import * as turf from "@turf/turf"
import bbox from '@turf/bbox';
import * as proj from "ol/proj";
import lcolor from "./lcolor";

/**
 * @description 根据经纬度和z值,生成铺满当前地图视图范围的canvas,采用反距离插值分析，基于turf,不重复造轮子
 * @author jadefan 
 * @param {*} options
 * @returns canvas in Map
 */
function lidwcanvas(options) {
  let points = options.gra;
  //let cellSize = options.cellSize;
  let property = options.zField;
  //let units = options.units;
  //let isDeal = options.isDeal ? options.isDeal : true;
  let weight = options.weight ? options.weight : 1;
  let mask = options.mask;
  //let isRaster = options.isRaster;
  //let gridType = options.gridType;
  let colorScale = options.colorScale;
  var opacity = options.opacity;
  var tooltip = options.tooltip;
  let grid, newgrid = {};
  let map = options.map;
  let width = map.getViewport().clientWidth;
  let height = map.getViewport().clientHeight;
  var minCoord, maxCoord;
  var data = [];
  var maskdata = [];
  var canvas, ctx;
  var eventMoveStart, eventMoveEnd;
  var canvasData = [];
  var left = map.getViewport().getBoundingClientRect().left
  var top = map.getViewport().getBoundingClientRect().top;

  getMapExtent();
  initGeoData()
  var raster = createCanvas();
  interpolateField();
  drawOverlay();
  initMaskData();
  bindEvent();

  function getMapExtent() {
    var bounds = map.getView().calculateExtent();
    var _min = [bounds[0], bounds[1]];
    var _max = [bounds[2], bounds[3]];
    minCoord = proj.toLonLat(_min);
    maxCoord = proj.toLonLat(_max);
  }

  function createCanvas() {
    //创建canvas 
    canvas = document.createElement("canvas");
    canvas.id = options.id;
    canvas.setAttribute("width", width);
    canvas.setAttribute("height", height);
    canvas.style.position = 'absolute';
    canvas.style.top = 0;
    canvas.style.left = 0;
    ctx = canvas.getContext("2d");
    map.getViewport().appendChild(canvas);
    //栅格数据
    var context = canvas.getContext('2d');
    var imageData = context.getImageData(0, 0, width, height);
    var data = imageData.data; // layout: [r, g, b, a, r, g, b, a, ...]
    return {
      imageData: imageData,
      isVisible: function isVisible(x, y) {
        var i = (y * width + x) * 4;
        return data[i + 3] > 0; // non-zero alpha means pixel is visible
      },
      set: function set(x, y, rgba) {
        var i = (y * width + x) * 4;
        data[i] = rgba[0];
        data[i + 1] = rgba[1];
        data[i + 2] = rgba[2];
        data[i + 3] = rgba[3];
        return this;
      }
    };
  }

  //初始化点位数据
  function initGeoData() {
    data = [];
    points.features.forEach(pt => {
      var x = pt.geometry.coordinates[0];
      var y = pt.geometry.coordinates[1];
      var z = pt.properties[property];
      data.push(dataProj(x, y, z))
    })
  }

  //初始化遮罩数据
  function initMaskData() {
    if (!mask) return;
    maskdata = [];
    mask.features[0].geometry.coordinates[0].forEach(pt => {
      var x = pt[0];
      var y = pt[1];
      var z = 0;
      maskdata.push(dataProj(x, y, z))
    })
    drawMask();
  }

  //绘制遮罩图形
  function drawMask() {
    ctx.beginPath();
    ctx.moveTo(maskdata[0][0], maskdata[0][1]);
    for (let i = 0; i < maskdata.length; i++) {
      ctx.lineTo(maskdata[i][0], maskdata[i][1]);
    }
    ctx.closePath();
    // ctx.lineWidth = 5;       //线条宽度
    // ctx.strokeStyle = "red"   //线条颜色
    // ctx.stroke();   //用于绘制线条

    ctx.fillStyle = 'rgba(225,225,225,1)';;
    ctx.fill();

  }

  function initGFSData() {

  }

  //将经纬度和参考值投影到画布上
  function dataProj(x, y, z) {
    var sx = (x - minCoord[0]) / (maxCoord[0] - minCoord[0]) * width;
    var sy = height - (y - minCoord[1]) / (maxCoord[1] - minCoord[1]) * height;
    var sz = z;
    return [sx, sy, sz];
  }

  //计算单个点
  function interpolate(t) {
    var zw = 0;
    var sw = 0;
    data.forEach(d => {
      var distance = Math.sqrt(Math.pow((d[0] - t[0]), 2) + Math.pow((d[1] - t[1]), 2))
      var zValue = d[2];
      var w = 1.0 / Math.pow(distance, weight);
      sw += w;
      zw += w * zValue;
    })

    return zw / sw;
  }

  //循环计算像素值及颜色
  function interpolateField() {

    canvasData = [];
    for (let i = 0; i < width; i += 4) {
      var canvasDataItem = [];
      for (let j = 0; j < height; j += 4) {
        //if (!isInMask([i, j])) continue;
        var v = interpolate([i, j]);
        var color = colorScalar(v);
        try {
          color[3] = opacity * 255;
        } catch (error) {
          console.log(v);
          console.log(color);
        }
        raster
          .set(i, j, color)
          .set(i, j + 1, color)
          .set(i, j + 2, color)
          .set(i, j + 3, color)

          .set(i + 1, j, color)
          .set(i + 1, j + 1, color)
          .set(i + 1, j + 2, color)
          .set(i + 1, j + 3, color)

          .set(i + 2, j, color)
          .set(i + 2, j + 1, color)
          .set(i + 2, j + 2, color)
          .set(i + 2, j + 3, color)

          .set(i + 3, j, color)
          .set(i + 3, j + 1, color)
          .set(i + 3, j + 2, color)
          .set(i + 3, j + 3, color);
        canvasDataItem.push(v);
        canvasDataItem.push(v);
        canvasDataItem.push(v);
        canvasDataItem.push(v);
      }
      canvasData.push(canvasDataItem);
      canvasData.push(canvasDataItem);
      canvasData.push(canvasDataItem);
      canvasData.push(canvasDataItem);
    }
  }

  function isInMask(lng, lat) {
    var poly = maskdata;
    for (var c = false, i = -1, l = poly.length, j = l - 1; ++i < l; j = i)
      ((poly[i][0] <= lng && lng < poly[j][0]) || (poly[j][0] <= lng && lng < poly[i][0])) &&
        (lat < (poly[j][1] - poly[i][1]) * (lng - poly[i][0]) / (poly[j][0] - poly[i][0]) + poly[i][1]) &&
        (c = !c);
    return c;
  }

  //根据级别计算过度色
  function colorScalar(value) {
    var preClr, preVal;
    for (let i = 0; i < colorScale.length; i++) {
      const scale = colorScale[i];
      var curVal = scale[0];
      var curClr = scale[1];
      if (scale[1].indexOf('#') > -1) {
        curClr = lcolor.colorToRgb(scale[1]);
      }

      if (value < scale[0]) {
        //计算颜色
        if (preClr) {
          var level = (value - preVal) / (curVal - preVal);
          var rc = curClr[0] - preClr[0];
          var gc = curClr[1] - preClr[1];
          var bc = curClr[2] - preClr[2];
          var r = preClr[0] + level * rc;
          var g = preClr[1] + level * gc;
          var b = preClr[2] + level * bc;
          return [r, g, b]
        } else {
          return curClr;
        }
      }
      //下一轮
      preVal = curVal;
      preClr = curClr;
    }
  }

  //更新canvas
  function drawOverlay() {
    ctx.putImageData(raster.imageData, 0, 0);
    ctx.globalCompositeOperation = "destination-in";
  }

  function clearCanvas() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  function bindEvent() {
    eventMoveStart = map.on('movestart', function (e) {
      clearCanvas();
    })
    eventMoveEnd = map.on('moveend', function (e) {
      getMapExtent();
      initGeoData();
      interpolateField();
      drawOverlay();
      initMaskData();
    })
    if (tooltip) { 
      let tipDiv = document.createElement('div');
      tipDiv.className = 'mouseTooltip';
      tipDiv.style.position = 'absolute';
      document.body.appendChild(tipDiv);

      canvas.onmousemove = function (e) {
        e.value = canvasData[e.layerX][e.layerY];
        tipDiv.style.display = 'block';
        tipDiv.style.left = e.clientX + 'px'
        tipDiv.style.top = e.clientY + 'px';
        tipDiv.innerHTML = tooltip(e);;

      }
      canvas.onmouseleave = function () {
        tipDiv.style.display = 'none';
      }
    }
  }

  //解除地图交互事件
  function removeEvent() {
    map.un('movestart', eventMoveStart.listener);
    map.un('moveend', eventMoveEnd.listener);
  }

  //清除渲染结果并接收绘制
  function clear() {
    clearCanvas();
    canvas.parentElement.removeChild(canvas);
    removeEvent();
  }

  //更新点位数据并重新绘制
  function update(gra) {
    points = gra; 
    getMapExtent();
    initGeoData();
    interpolateField();
    drawOverlay();
    initMaskData();
  }

  function printtime(info) {
    console.log(info + ":" + new Date().getMinutes() + ':' + new Date().getSeconds())
  }

  newgrid.interpolateSingle = interpolate;
  newgrid.canvas = canvas;
  newgrid.clear = clear;
  newgrid.update = update;

  return newgrid;
}

export default lidwcanvas