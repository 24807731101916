import { TweenMax, Power2, TimelineLite } from "gsap/TweenMax";

var ltween = {
  _tailFactor: 0.05,
  _headPercentage: 0.07,
  createBezierPath(pointCollection) {
    var numberOfPts = 100;
    var position = { x: pointCollection[0].x, y: pointCollection[0].y };
    var tween = TweenMax.to(position, numberOfPts, { bezier: pointCollection, ease: Linear.easeNone });
    var path = [];
    for (var i = 0; i <= numberOfPts; i++) {
      tween.time(i);
      path.push([position.x, position.y]);
    }
    return path;
  },
  createBezierPathPoly(pointCollection) {
    pointCollection.push(pointCollection[0]);
    return this.createBezierPath(pointCollection);
  },
  createArrow(pointCollection) {
    var firstPtScreen = pointCollection[0];
    var candidateScreen = pointCollection[1];
    var widthScreen = candidateScreen.x - firstPtScreen.x;
    var heightScreen = candidateScreen.y - firstPtScreen.y;
    var sideScreen = Math.sqrt(widthScreen * widthScreen + heightScreen * heightScreen);
    var heightRatio = heightScreen / sideScreen;
    var widthRatio = widthScreen / sideScreen;
    var sideScreenQuarter = 0.25 * widthRatio * sideScreen;
    var sideQuarterMultiHWratio = 0.25 * sideScreen / (heightScreen / widthScreen);
    sideScreen *= 0.25 * heightRatio;
    var sideQuarterMultiHWratio2 = sideScreen;
    widthScreen = [
      [widthScreen, heightScreen],
      [widthScreen - sideScreenQuarter * (1 + 24 / sideQuarterMultiHWratio), heightScreen + 24 * widthRatio - sideQuarterMultiHWratio2],
      [widthScreen - sideScreenQuarter * (1 + 12 / sideQuarterMultiHWratio), heightScreen + 12 * widthRatio - sideQuarterMultiHWratio2],
      [-12 * heightRatio, 12 * widthRatio],
      [12 * heightRatio, -12 * widthRatio],
      [widthScreen - sideScreenQuarter * (1 - 12 / sideQuarterMultiHWratio), heightScreen - 12 * widthRatio - sideQuarterMultiHWratio2],
      [widthScreen - sideScreenQuarter * (1 - 24 / sideQuarterMultiHWratio), heightScreen - 24 * widthRatio - sideQuarterMultiHWratio2],
      [widthScreen, heightScreen]
    ];
    var path = this._toPolygon(widthScreen, firstPtScreen.x, firstPtScreen.y);
    return path;
  },
  _toPolygon: function (pointCollection, screenPtX, screenPtY) {
    var pts = [];
    Array.map(pointCollection, function (b) {
      pts.push([b[0] + screenPtX, b[1] + screenPtY])
    });
    return pts
  },
  createFreeArrow(pointCollection) {
    var candidatePoint = pointCollection[pointCollection.length - 1];
    var tempArray = [];
    var leftArray = [], rightArray = [];
    Array.forEach(pointCollection, function (e) {
      tempArray.push({ x: e.x, y: e.y });
    });
    //tempArray.push({ x: candidatePoint.x, y: candidatePoint.y });
    var angleArray = this._vertexAngle(tempArray);
    var totalL = this._ptCollectionLen(tempArray, 0);
    for (var i = 0, len = tempArray.length - 1; i < len; i++) {
      var partialLen = this._ptCollectionLen(tempArray, i);
      partialLen += totalL / 2.4;
      var pt1 = { x: (this._tailFactor) * partialLen * Math.cos(angleArray[i]) + tempArray[i].x, y: (this._tailFactor) * partialLen * Math.sin(angleArray[i]) + tempArray[i].y };
      var pt2 = { x: -1 * (this._tailFactor) * partialLen * Math.cos(angleArray[i]) + tempArray[i].x, y: -1 * (this._tailFactor) * partialLen * Math.sin(angleArray[i]) + tempArray[i].y };

      leftArray.push(pt1);
      rightArray.push(pt2);
    }
    leftArray.push({ x: candidatePoint.x, y: candidatePoint.y });
    rightArray.push({ x: candidatePoint.x, y: candidatePoint.y });

    leftArray = this.CreateBezierPathPCOnly(leftArray, 70);
    leftArray.splice(Math.floor((1 - this._headPercentage) * 70), Number.MAX_VALUE);

    rightArray = this.CreateBezierPathPCOnly(rightArray, 70);
    rightArray.splice(Math.floor((1 - this._headPercentage) * 70), Number.MAX_VALUE);

    var headPath = this.CreateArrowHeadPathEx(leftArray[leftArray.length - 1], candidatePoint, rightArray[rightArray.length - 1], this._ptCollectionLen(tempArray, 0), this._headPercentage, 15);
    var ring = [];
    ring = ring.concat(leftArray);
    ring = ring.concat(headPath);
    ring = ring.concat(rightArray.reverse());
    ring.push(ring[0]);
    var path = [];
    ring.forEach(r => {
      path.push([r.x, r.y])
    })
    return path;
  },
  CreateArrowHeadPathEx: function (pt1, candidatePt, pt2, totalLen, headPercentage, headAngle) {
    var headSizeBaseRatio = 1.7;
    var headBaseLen = totalLen * headPercentage;
    var headSideLen = headBaseLen * headSizeBaseRatio;
    var angle1 = this.twoPtsAngle(candidatePt, pt1);
    var angle2 = this.twoPtsAngle(candidatePt, pt2);
    var midAngle = (Math.abs(angle1 - angle2)) / 2;
    if (Math.abs(angle1 - angle2) > Math.PI * 1.88) midAngle += Math.PI;
    var len = Math.sqrt(headBaseLen * headBaseLen + headSideLen * headSideLen - 2 * headSideLen * headBaseLen * Math.cos(midAngle + headAngle / 180 * Math.PI));
    var upAngle = Math.asin(headBaseLen * Math.sin(midAngle + headAngle / 180 * Math.PI) / len);
    var centAngle = upAngle + headAngle / 180 * Math.PI;
    var result = headBaseLen * Math.sin(Math.PI - centAngle - midAngle) / Math.sin(centAngle);
    var path = [];

    path.push({ x: candidatePt.x + result * Math.cos(angle1), y: candidatePt.y + result * Math.sin(angle1) });
    path.push({ x: candidatePt.x + headSideLen * Math.cos(angle1 - headAngle / 180 * Math.PI), y: candidatePt.y + headSideLen * Math.sin(angle1 - headAngle / 180 * Math.PI) });
    path.push(candidatePt);
    path.push({ x: candidatePt.x + headSideLen * Math.cos(angle2 + headAngle / 180 * Math.PI), y: candidatePt.y + headSideLen * Math.sin(angle2 + headAngle / 180 * Math.PI) });
    path.push({ x: candidatePt.x + result * Math.cos(angle2), y: candidatePt.y + result * Math.sin(angle2) });
    return path;
  },
  CreateBezierPathPCOnly: function (pointCollection, numberOfPts) {
    var position = { x: pointCollection[0].x, y: pointCollection[0].y };
    var tween = TweenMax.to(position, numberOfPts, { bezier: pointCollection, ease: Linear.easeNone });
    //ease:Power1.easeInOut  ease: Linear.easeNone
    var path = [];
    for (var i = 0; i <= numberOfPts; i++) {
      tween.time(i);
      path.push({ x: position.x, y: position.y });
    }

    return path;

  },
  twoPtsAngle: function (pt1, pt2) {
    var angle = Math.acos((pt2.x - pt1.x) / this._2PtLen(pt1, pt2));
    if (pt2.y < pt1.y) {
      angle = 2 * Math.PI - angle;
    }
    return angle;
  },
  _vertexAngle: function (ptc) {
    var segmentAngle = [], vertexAngle = [], left = [];
    for (var i = 0, len = ptc.length - 1; i < len; i++) {
      //0 -2pi
      var x = this.twoPtsAngle(ptc[i], ptc[i + 1]);

      segmentAngle.push(x);
    }
    x = this.twoPtsAngle(ptc[0], ptc[1]);
    vertexAngle.push(x += Math.PI / 2);
    for (i = 1; i < len; i++) {
      //var x = segmentAngle[i - 1] < segmentAngle[i] ? segmentAngle[i - 1] : segmentAngle[i] + polyline._3PtAngleAngleHalf(ptc[i - 1], ptc[i], ptc[i + 1]);
      x = (segmentAngle[i - 1] + segmentAngle[i]) / 2;
      if (segmentAngle[i - 1] < Math.PI && segmentAngle[i] - Math.PI > segmentAngle[i - 1]) {
        x += Math.PI;
      }
      else if (segmentAngle[i - 1] > Math.PI && segmentAngle[i] < segmentAngle[i - 1] - Math.PI) {
        x += Math.PI;
      }
      x += Math.PI / 2;
      vertexAngle.push(x);
    }
    return vertexAngle;
  },
  _2PtLen: function (pt1, pt2) {
    return Math.sqrt((pt1.x - pt2.x) * (pt1.x - pt2.x) + (pt1.y - pt2.y) * (pt1.y - pt2.y));
  },
  _ptCollectionLen: function (ptc, startIndex) {
    var len = 0;
    for (var i = startIndex, pathLen = ptc.length - 1; i < pathLen; i++) {
      len += this._2PtLen(ptc[i], ptc[i + 1]);
    }
    return len;
  },
}

export default ltween;