import { ADMIN, COMMON } from "@/config/interface.config.js";

export default {
  loginUser: `post:/${ADMIN}/login/loginUser`, //登陆
  getUserByAccountPhonepwd: `get:/${ADMIN}/baseUserInfo/getUserByAccountPhonepwd`, //验证用户名是否正确
  sendVerificationCode: `post:/${COMMON}/sendMessage/sendVerificationCode`, //发送验证码，需要完整手机号
  validVerificationCode: `post:/${COMMON}/sendMessage/validVerificationCode`, //验证验证码是否正确
  sendByGetUserAccount: `post:/${ADMIN}/baseUserInfo/sendByGetUserAccount`, //发送验证码-新的，不需要手机号
  retrievePwd: `post:/${ADMIN}/baseUserInfo/retrievePwd`, //确认修改
  updateUserPhone: `post:/${ADMIN}/baseUserInfo/updateUserPhone`, //
  existsUserName: `get:/${ADMIN}/baseUserInfo/existsUserName` //验证登录名是否重复
};
