<template>
  <div id="app" :class="themeStyle">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      // theme: this.$store.state.user.theme, // 获取默认主题,
      themeStyle: "default-theme"
    };
  },
  created() {
    require("./assets/css/theme/default/style.less");
  }
};
</script>
<style>
#app {
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 1280px;
  overflow: hidden;
}
</style>
