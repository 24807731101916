import Vue from "vue";
import VueRouter from "vue-router";
import tongjifenxi from "./tongjifenxi";//统计分析

// 解决重复点菜单报红问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    component: () => import(`@/views/loginIndex.vue`),
    redirect: "/loginIndex"
  },
  {
    path: "/loginIndex",
    name: "loginIndex",
    component: () => import(`@views/loginIndex.vue`)
  },
  {
    path: "/blank",
    name: "blank",
    component: () => import(`@views/blank.vue`)
  },
  {
    path: "/index",
    name: "index",
    // redirect: "/index/homePage",
    component: () => import(`@components/index.vue`),
    children: [
      {
        path: "videlList",
        name: "videlList",
        component: () => import(`@components/videoList/index.vue`)
      },
      {
        path: "leftMenuIndex",
        component: () => import(`@/components/leftMenuIndex`),
        children: [
          ...tongjifenxi,
          {
            path: "videoManage",
            name: "videoManage",
            component: () => import(`@components/videoManage/videoManage.vue`)
          },
          {
            path: "videoManageEdit",
            name: "videoManageEdit",
            component: () =>
              import(`@components/videoManage/videoManageEdit.vue`)
          },
          {
            path: "videoManageDetail",
            name: "videoManageDetail",
            component: () =>
              import(`@components/videoManage/videoManageDetail.vue`)
          },
          {
            path: "userAuthorization",
            name: "userAuthorization",
            component: () =>
              import(`@components/videoManage/userAuthorization.vue`)
          },
          {
            path: "userAuthorizationEdit",
            name: "userAuthorizationEdit",
            component: () =>
              import(`@components/videoManage/userAuthorizationEdit.vue`)
          },
          {
            path: "userAuthorizationDetail",
            name: "userAuthorizationDetail",
            component: () =>
              import(`@components/videoManage/userAuthorizationDetail.vue`)
          },
          {
            path: "qrcodeAuthorization",
            name: "qrcodeAuthorization",
            component: () =>
              import(`@components/videoManage/qrcodeAuthorization.vue`)
          },
          {
            path: "qrcodeAuthorizationDetail",
            name: "qrcodeAuthorizationDetail",
            component: () =>
              import(`@components/videoManage/qrcodeAuthorizationDetail.vue`)
          },
          {
            path: "directAuthorization",
            name: "directAuthorization",
            component: () =>
              import(`@components/videoManage/directAuthorization.vue`)
          },
          {
            path: "allVideo",
            name: "allVideo",
            component: () =>
              import(`@components/VideoApproval/allVideo.vue`)
          },
          {
            path: "myApplication",
            name: "myApplication",
            component: () =>
              import(`@components/VideoApproval/myApplication.vue`)
          },
          {
            path: "processed",
            name: "processed",
            component: () =>
              import(`@components/VideoApproval/processed.vue`)
          },
          {
            path: "processing",
            name: "processing",
            component: () =>
              import(`@components/VideoApproval/processing.vue`)
          }
        ]
      },
      {
        path: "*",
        component: () => import("@/common/page/notFound")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  routes
});

export default router;
