import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/reset.css"; // 重置浏览器默认样式
import "./assets/extendcss/resetElement.less"; // 重置element样式
import "./assets/css/common.css"; // 公共样式
import "element-ui/lib/theme-chalk/index.css";
import ElementUI from "element-ui";
import ElTableBar from "el-table-bar-base";
import "el-table-bar-base/lib/ElTableBar.css";

import "./assets/icon/common/iconfont.css";
import "./assets/icon/video/iconfont.css";

// axios
import axios from "axios";
/* 滚动条-vuescroll */
import vuescroll from "vuescroll/dist/vuescroll-native";
/* 滚动条-nicescroll */
import "jquery.nicescroll";
import moment from "moment";
// BUS
import bus from "./utils/bus";
// 富文本编辑
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);
/* 地图 */
import lgis from "./lgis";
import "./lgis/index.css";
// 加密算法
import aesutil from "./utils/AESUtil";
// echarts
import * as echarts from "echarts";
// jQuery
import $ from "jquery";
Vue.config.productionTip = false;
import Api from "./api";
import { adoptData, setConfigMap } from "@/config/systemConfig";
// import bignumber from "bignumber";
const BigNumber = require("bignumber.js");

/* 地图 */
Vue.prototype.lgis = lgis;
// element ui
Vue.use(ElementUI);
Vue.use(ElTableBar);
// api
Vue.use(Api);
moment.locale("zh-cn");
Vue.prototype.$moment = moment;
Vue.prototype.BigNumber = BigNumber;
// 滚动条
Vue.use(vuescroll, {
  ops: {
    rail: {
      size: "6px"
    },
    bar: {
      background: "#ccc",
      onlyShowBarOnScroll: false,
      keepShow: false
    },
    scrollPanel: {
      scrollingX: false,
      scrollingY: true
    }
  } // 在这里设置全局默认配置
});
Vue.prototype.$aesutil = aesutil;

// http请求拦截器
axios.interceptors.request.use(
  config => {
    config.headers["token"] = sessionStorage.getItem("token");
    return config;
  },
  error => {
    console.log(error, "http请求拦截器-失败");
    return Promise.reject(error);
  }
);
/*axios设置*/
axios.defaults.withCredentials = true;
axios.defaults.responseType = "json";

/* 配置全局变量 */
Vue.prototype.BUS = bus;
Vue.prototype.$echarts = echarts;
Vue.prototype.$ = $;
Vue.prototype.$axios = axios;
// 路由监听
router.beforeEach((to, from, next) => {
  const mapData = ["loginIndex"].concat(adoptData);
  // console.log("mapData", mapData);
  if (mapData.indexOf(to.name) > -1) {
    next();
  } else {
    if (sessionStorage.getItem("isLoginCheck") == 1) {
      next();
    } else {
      next({
        name: "loginIndex"
      });
    }
  }
});
function serverFun(data) {
  /* 权限http地址 */
  Object.entries(data).forEach(([key, value]) => {
    Vue.prototype[key] = value;
  });
  setConfigMap(data.VUE_APP_SYSTEM_NAME);
}
// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === "development") {
  /* 开发环境 */
  serverFun(process.env);
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
} else {
  /* 生产环境（NODE_ENV="production"） */
  let arr = window.location.hostname.split(".");
  let str = arr[0] + arr[1];
  let url = "";
  //192168，高碑店打包用这个
  if (str === "17216" || str === "192168") {
    url = "serverConfig.json?time=";
  } else {
    url = "serverConfigExternal.json?time=";
  }
  axios(url + new Date().getTime()).then(({ data }) => {
    serverFun(data);
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  });
}
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount("#app");
