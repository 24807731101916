const configMap = new Map([
  // 默认展示配置
  [
    "default",
    {
      VUE_APP_LOGIN_BG_IMG: {
        backgroundImage: `url(${require("@/assets/img/bj1.png")})` // 登录页背景图
      },
      VUE_APP_LOGIN_NAME_IMG: require("@/assets/img/logo.png"), // 登录页系统名称图片
      VUE_APP_LOGIN_NAME_IMG_STYLE: {},
      VUE_APP_LOGIN_BG_CENTER_IMG: require("@/assets/img/bg_center.png"),
      VUE_APP_SYSTEM_TITLE_NAME: "雪迪龙视频学习系统",
      VUE_APP_SYSTEM_TITLE_NAME_IMG_STYLE: {
        backgroundImage: `url(${require("@/assets/img/indexLogo.png")})`
      },
      VUE_APP_UNIT: "版权所有：北京雪迪龙科技股份有限公司"
    }
  ]
]);
let loginRoute = [];
let adoptData = [];
configMap.forEach((value, key) => {
  if (key !== "default") {
    loginRoute.push(
      // 企业端
      {
        path: "/loginIndex_" + key,
        name: "loginIndex",
        component: () => import(`@/views/loginIndex.vue`)
      }
    );
    adoptData.push("loginIndex_" + key);
  }
});
function setConfigMap(systemName) {
  configMap.forEach((value, key) => {
    if (key === systemName) {
      configMap.set("default", value);
    }
  });
}
function setDocumentTitle() {
  const systemName = sessionStorage.getItem("systemName") || "default";
  const systemConfig = configMap.get(systemName);
  document.title = systemConfig.VUE_APP_SYSTEM_TITLE_NAME;
}
export { configMap, loginRoute, adoptData, setConfigMap, setDocumentTitle };
