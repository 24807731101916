const app = {
  state: {
    menus: [],
    keys: [],
    user: {}
  },
  mutations: {
    UPDATE_MENU: (state, menus) => {
      state.menus = menus;
    },
    UPDATE_KEY: (state, keys) => {
      state.keys = keys;
    },
    UPDATE_USER: (state, user) => {
      state.user = user;
    },
    CLEAR: state => {
      state.menus = [];
      state.keys = [];
      state.user = {};
    }
  },
  actions: {
    init: ({ commit }) => {
      return new Promise(resolve => {
        commit("UPDATE_MENU", []);
        commit("UPDATE_KEY", ["key0", "key1"]);
        commit("UPDATE_USER", {
          id: "admin"
        });
        resolve();
      });
    },
    clear: ({ commit }) => {
      commit("CLEAR");
    }
  }
};

export default app;
