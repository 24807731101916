import * as turf from "@turf/turf"

function llineChunk(options) {
  var paths = options.paths;
  var units = options.units;
  var length = options.length;

  var chunks = [];
  var curDistance = 0;
  //起点
  chunks.push(paths[0]);
  //遍历
  for (let i = 0; i < paths.length-1; i++) {
    var p1 = paths[i];
    var p2 = paths[i + 1];
    var distance = getdistance(p1, p2);

    //取出等待分段距离
    var waitDistance = length - curDistance;

    if (distance < waitDistance) {
      //当前距离小于等待分段距离
      //将当前距离加到已分段距离上
      curDistance += distance;
      continue;
    } else {
      //当前距离大于等待分段距离
      //在本线段上截取等待距离的位置点
      var newStart = p1;
      while (distance > waitDistance) {
        var p3 = along(newStart, p2, waitDistance);
        newStart = p3.geometry.coordinates;
        chunks.push(newStart);
        curDistance = 0;
        waitDistance = length;
        distance = getdistance(newStart, p2);
      }
      curDistance += distance;
    }
  }
  //终点
  chunks.push(paths[paths.length-1]);

  return chunks;
 

  //计算两点距离
  function getdistance(point1, point2) {
    var from = turf.point(point1);
    var to = turf.point(point2);
    return turf.distance(from, to, {
      units: units
    });
  }

  //根据距离返回两点间的点
  function along(point1, point2, distance) {
    var line = turf.lineString([point1, point2]);
    var along = turf.along(line, distance, { units: units });
    return along;
  }
}

export default llineChunk;