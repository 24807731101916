import * as turf from "@turf/turf"
import lcolor from "./lcolor";


//高斯扩散模型
//基于turf,不重复造轮子
function lgaussAir(options) {
  let points = options.gra;
  let cellSize = options.cellSize;
  let property = options.zField;
  let units = options.units;
  let isDeal = options.isDeal ? options.isDeal : true;
  let weight = options.weight ? options.weight : 1;
  let mask = options.mask;
  let isRaster = options.isRaster;
  let gridType = options.gridType;
  let grid, newgrid = {};
  let colorScale = options.colorScale;
  let S = options.stability ? options.stability : 'C';
  let angle = options.angle;
  let origin = options.origin;
  let H = options.height;
  let Q = options.strength;
  let u = options.speed;
  let t = options.duration;

  var x0 = origin[0];
  var y0 = origin[1];
  //风向周旋转角度
  var θn = angle < 180 ? angle + 180 : angle - 180;
  //风向轴上的单位向量
  var xn = 0 * Math.cos(θn * Math.PI / 180) + 1 * Math.sin(θn * Math.PI / 180);
  var yn = 0 * Math.sin(θn * Math.PI / 180) + 1 * Math.cos(θn * Math.PI / 180);


  if (isDeal) {
    createGrid();
    interpolateGrid()
  }

  //生成网格
  function createGrid() {
    var bound = u * 3600 / 1000 * t / 111;
    let box = [
      origin[0] - bound,
      origin[1] - bound,
      origin[0] + bound,
      origin[1] + bound,
    ]
    let gridOptions = {
      gridType: gridType,
      property: property,
      units: units
    }
    switch (gridType) {
      case 'point':
      case 'points':
        grid = turf.pointGrid(box, cellSize, gridOptions);
        break;
      case 'square':
      case 'squares':
        grid = turf.squareGrid(box, cellSize, gridOptions);
        break;
      case 'hex':
      case 'hexes':
        grid = turf.hexGrid(box, cellSize, gridOptions);
        break;
      case 'triangle':
      case 'triangles':
        grid = turf.triangleGrid(box, cellSize, gridOptions);
        break;
      default:
        throw new Error('invalid gridType');
    }

    let newgrid = [];
    turf.featureEach(grid, function (gridFeature) {
      var gridPoint = (gridType === 'points') ? gridFeature : turf.centroid(gridFeature);
      let x = gridPoint.geometry.coordinates[0];
      let y = gridPoint.geometry.coordinates[1];
      //过滤掩膜之外的点 
      if (mask) {
        let polygon = mask.features[0].geometry;
        let isin = turf.booleanPointInPolygon([x, y], polygon);
        if (isin) newgrid.push(gridFeature);
      } else {
        newgrid.push(gridFeature);
      }

    })
    grid = turf.featureCollection(newgrid);

  }

  //计算网格值
  function interpolateGrid() {
    var results = [];
    var minValue = options.minValue ? options.minValue : 0;
    turf.featureEach(grid, function (gridFeature) {
      var gridPoint = (gridType === 'points') ? gridFeature : turf.centroid(gridFeature);
      if (gridPoint.features) gridPoint = gridPoint.features[0];
      var x = gridPoint.geometry.coordinates[0];
      var y = gridPoint.geometry.coordinates[1];
      var value = gaussC(x, y, 0);
      if (value > minValue) {
        var newFeature = turf.clone(gridFeature);
        newFeature.properties[property] = value;
        if (colorScale) {
          var color = colorScalar(value);
          newFeature.properties._symbol = {
            "color": color,
            "type": "esriSFS",
            "style": "STYLE_NULL",
            outline:{
              "color": color,
              "width": 1,
            }
          }
        }
        results.push(newFeature);
      }
    });
    newgrid = turf.featureCollection(results);
  }

  //计算单个点
  function gaussC(x, y, z) {
    z = z ? z : 0;

    var radian = angle * Math.PI / 180;
    var cx = Math.abs(x - origin[0]);
    var cy = Math.abs(y - origin[1]);
    var d = Math.sqrt(Math.pow(cx, 2) + Math.pow(cy, 2));

    //源为原点的坐标
    var xc = x - origin[0];
    var yc = y - origin[1];

    // var θx = 0;
    // if (angle < 90) θx = 90 - angle;
    // else if (angle < 180) θx = angle - 90;
    // else if (angle < 270) θx = 270 - angle;
    // else if (angle < 360) θx = angle - 270;

    //var θ = Math.abs(θx - Math.atan(cy / cx) * 180 / Math.PI);
    var cosθ = (xc * xn + yc * yn) / (Math.sqrt(xc * xc + yc * yc) * Math.sqrt(xn * xn + yn * yn));
    var θ = Math.acos(cosθ);
    //var dx = Math.abs(x - origin[0]) * 111 * 1000;
    //var dy = Math.abs(y - origin[1]) * 111 * 1000;
    var dx = d * Math.cos(θ) * 111 * 1000;
    var dy = d * Math.sin(θ) * 111 * 1000;

    var σy = GetQy(dx, S);
    var σz = GetQz(dx, S);
    var C1 = Q / (2 * Math.PI * u * σy * σz);
    var C2 = Math.exp(-dy * dy / (2 * σy * σy));
    var C3 = Math.exp(-(z - H) * (z - H) / (2 * σy * σy));
    var C4 = Math.exp(-(z + H) * (z + H) / (2 * σz * σz));
    var C = C1 * C2 * (C3 + C4);
    return C;
  }

  //获取稳定度对应Qy，x为距离，Q大气稳定度
  function GetQy(x, Q) {
    var a = 0.0;
    var r = 0.0;
    if (Q == "A") {
      if (x <= 1000) {
        a = 0.901074;
        r = 0.425809;
      }
      else {
        a = 0.850934;
        r = 0.602052;
      }
    }
    else if (Q == "B") {
      if (x <= 1000) {
        a = 0.914370;
        r = 0.281846;
      }
      else {
        a = 0.865014;
        r = 0.396353;
      }
    }
    else if (Q == "B-C") {
      if (x <= 1000) {
        a = 0.919325;
        r = 0.229500;
      }
      else {
        a = 0.875086;
        r = 0.314238;
      }
    }

    else if (Q == "C") {
      if (x <= 1000) {
        a = 0.924279;
        r = 0.177154;
      }
      else {
        a = 0.885157;
        r = 0.232123;
      }
    }
    else if (Q == "C-D") {
      if (x <= 1000) {
        a = 0.926849;
        r = 0.143940;
      }
      else {
        a = 0.886940;
        r = 0.189396;
      }
    }

    else if (Q == "D") {
      if (x <= 1000) {
        a = 0.929418;
        r = 0.110726;
      }
      else {
        a = 0.888723;
        r = 0.146669;
      }
    }

    else if (Q == "D-E") {
      if (x <= 1000) {
        a = 0.925118;
        r = 0.0985631;
      }
      else {
        a = 0.892794;
        r = 0.124308;
      }
    }

    else if (Q == "E") {
      if (x <= 1000) {
        a = 0.920818;
        r = 0.0864001;
      }
      else {
        a = 0.896864;
        r = 0.101947;
      }
    }

    else {
      if (x <= 1000) {
        a = 0.929418;
        r = 0.0553634;
      }
      else {
        a = 0.888723;
        r = 0.0733348;
      }
    }

    var Qz = Math.pow(x, a) * r;
    return Qz;
  }

  //获取稳定度对应Qz，x为距离，Q大气稳定度
  function GetQz(x, Q) {
    var a = 0.0;
    var r = 0.0;
    if (Q == "A") {
      if (x <= 300) {
        a = 1.12154;
        r = 0.0799904;
      }
      else if (x > 300 || x <= 500) {
        a = 1.51360;
        r = 0.00854771;
      }
      else {
        a = 2.10881;
        r = 0.000211545;
      }

    }

    else if (Q == "B") {
      if (x <= 500) {
        a = 0.964435;
        r = 0.127190;
      }
      else {
        a = 0.109356;
        r = 0.057025;
      }
    }
    else if (Q == "B-C") {
      if (x <= 500) {
        a = 0.941015;
        r = 0.114682;
      }
      else {
        a = 1.00770;
        r = 0.0757182;
      }
    }
    else if (Q == "C") {
      a = 0.917595;
      r = 0.106803;
    }

    else if (Q == "C-D") {
      if (x <= 2000) {
        a = 0.838628;
        r = 0.126152;
      }
      else if (x > 2000 || x <= 10000) {
        a = 0.756410;
        r = 0.235667;
      }
      else {
        a = 0.815575;
        r = 0.136659;
      }
    }
    else if (Q == "D") {
      if (x <= 1000) {
        a = 0.826212;
        r = 0.104634;
      }
      else if (x > 1000 || x <= 10000) {
        a = 0.632023;
        r = 0.400167;
      }
      else {
        a = 0.55536;
        r = 0.810763;
      }
    }
    else if (Q == "D-E") {
      if (x <= 2000) {
        a = 0.776864;
        r = 0.111771;
      }
      else if (x > 2000 || x <= 10000) {
        a = 0.572347;
        r = 0.5289922;
      }
      else {
        a = 0.499149;
        r = 1.03810;
      }
    }
    else if (Q == "E") {
      if (x <= 1000) {
        a = 0.788370;
        r = 0.0927529;
      }
      else if (x > 1000 || x <= 10000) {
        a = 0.565188;
        r = 0.433384;
      }
      else {
        a = 0.414743;
        r = 1.73241;
      }
    }
    else {
      if (x <= 1000) {
        a = 0.784400;
        r = 0.0620765;
      }
      else if (x > 1000 || x <= 10000) {
        a = 0.525969;
        r = 0.370015;
      }
      else {
        a = 0.322659;
        r = 2.40691;
      }
    }


    var Qy = Math.pow(x, a) * r;
    return Qy;

  }

  //根据级别计算过度色
  function colorScalar(value) {
    var preClr, preVal;
    for (let i = 0; i < colorScale.length; i++) {
      const scale = colorScale[i];
      var curVal = scale[0];
      var curClr = scale[1];
      if (scale[1].indexOf('#') > -1) {
        curClr = lcolor.colorToRgb(scale[1]);
      }

      if (value < scale[0]) {
        //计算颜色
        if (preClr) {
          var level = (value - preVal) / (curVal - preVal);
          var rc = curClr[0] - preClr[0];
          var gc = curClr[1] - preClr[1];
          var bc = curClr[2] - preClr[2];
          var r = preClr[0] + level * rc;
          var g = preClr[1] + level * gc;
          var b = preClr[2] + level * bc;
          return [r, g, b]
        } else {
          return curClr;
        }
      }
      //下一轮
      preVal = curVal;
      preClr = curClr;
    }
  }

  return {
    single: gaussC,
    gra: newgrid
  };
}

export default lgaussAir