'use strict';
function comfun() {
  return {
    constructor : function() {
      console.log("plot.comfun");
    },
    TWO_PI : 6.28318530717959,
    HALF_PI : 1.5707963267949,
    FITTING_COUNT : 100,
    ZERO_TOLERANCE : 0.0001,
    fa : function(o, n) {
      return Math.sqrt(Math.pow(o.x - n.x, 2) + Math.pow(o.y - n.y, 2));
    },
    fb : function(options) {
      /** @type {number} */
      var ret = 0;
      /** @type {number} */
      var name = 0;
      for (; name < options.length - 1;) {
        ret = ret + this.fa(options[name], options[name + 1]);
        name++;
      }
      return ret;
    },
    fc : function(e, a) {
      return new Point((e.x + a.x) / 2, (e.y + a.y) / 2, e.spatialReference);
    },
    fd : function(range) {
      return Math.pow(this.fb(range), 0.99);
    },
    fe : function(options, opts, test) {
      var that = new Point((options.x + opts.x) / 2, (options.y + opts.y) / 2, options.spatialReference);
      var delta = new Point(that.x - options.y + opts.y, that.y + options.x - opts.x, options.spatialReference);
      var coords = new Point((options.x + test.x) / 2, (options.y + test.y) / 2, options.spatialReference);
      var currPos = new Point(coords.x - options.y + test.y, coords.y + options.x - test.x, options.spatialReference);
      return this.ff(that, delta, coords, currPos);
    },
    ff : function(self, event, point, start) {
      var gridSize;
      var p3x2;
      var y;
      var i;
      if (self.y == event.y) {
        /** @type {number} */
        gridSize = (start.x - point.x) / (start.y - point.y);
        p3x2 = gridSize * (self.y - point.y) + point.x;
        y = self.y;
        return new Point(p3x2, y, self.spatialReference);
      }
      if (point.y == start.y) {
        /** @type {number} */
        i = (event.x - self.x) / (event.y - self.y);
        p3x2 = i * (point.y - self.y) + self.x;
        y = point.y;
        return new Point(p3x2, y, self.spatialReference);
      }
      /** @type {number} */
      i = (event.x - self.x) / (event.y - self.y);
      /** @type {number} */
      gridSize = (start.x - point.x) / (start.y - point.y);
      /** @type {number} */
      y = (i * self.y - self.x - gridSize * point.y + point.x) / (i - gridSize);
      p3x2 = i * y - i * self.y + self.x;
      return new Point(p3x2, y, self.spatialReference);
    },
    fg : function(pos, e) {
      var oldOffset;
      /** @type {number} */
      var theta = Math.asin(Math.abs(e.y - pos.y) / this.fa(pos, e));
      if (e.y >= pos.y && e.x >= pos.x) {
        /** @type {number} */
        oldOffset = theta + Math.PI;
      } else {
        if (e.y >= pos.y && e.x < pos.x) {
          /** @type {number} */
          oldOffset = this.TWO_PI - theta;
        } else {
          if (e.y < pos.y && e.x < pos.x) {
            /** @type {number} */
            oldOffset = theta;
          } else {
            if (e.y < pos.y && e.x >= pos.x) {
              /** @type {number} */
              oldOffset = Math.PI - theta;
            }
          }
        }
      }
      return oldOffset;
    },
    fh : function(event, str, asset) {
      /** @type {number} */
      var xoffset = this.fg(str, event) - this.fg(str, asset);
      return xoffset < 0 ? xoffset + this.TWO_PI : xoffset;
    },
    fi : function(actor, cb, target) {
      return (target.y - actor.y) * (cb.x - actor.x) > (cb.y - actor.y) * (target.x - actor.x);
    },
    fj : function(index, e, mousePos) {
      var p3x2 = e.x + index * (mousePos.x - e.x);
      var cornerOffset = e.y + index * (mousePos.y - e.y);
      return new Point(p3x2, cornerOffset, e.spatialReference);
    },
    fk : function(v, e, index, model, c) {
      /** @type {number} */
      v = Math.max(Math.min(v, 1), 0);
      /** @type {number} */
      var y = 1 - v;
      /** @type {number} */
      var t = v * v;
      /** @type {number} */
      var x = t * v;
      /** @type {number} */
      var d = y * y;
      /** @type {number} */
      var s = d * y;
      /** @type {number} */
      var p3x2 = s * e.x + 3 * d * v * index.x + 3 * y * t * model.x + x * c.x;
      /** @type {number} */
      var cornerOffset = s * e.y + 3 * d * v * index.y + 3 * y * t * model.y + x * c.y;
      return new Point(p3x2, cornerOffset, e.spatialReference);
    },
    fl : function(e, n, l, t, value) {
      if (typeof value === "undefined" || value == null || value === undefined) {
        /** @type {boolean} */
        value = true;
      }
      var m = this.fg(e, n);
      var a = value ? m + l : m - l;
      /** @type {number} */
      var tx = t * Math.cos(a);
      /** @type {number} */
      var offset = t * Math.sin(a);
      return new Point(n.x + tx, n.y + offset, e.spatialReference);
    },
    fm : function(args, i, callback, req) {
      var x;
      var y;
      var angle;
      /** @type {!Array} */
      var line = [];
      /** @type {number} */
      var c = req - callback;
      c = c < 0 ? c + this.TWO_PI : c;
      /** @type {number} */
      var columnDelta = 0;
      for (; columnDelta <= this.FITTING_COUNT;) {
        angle = callback + c * columnDelta / this.FITTING_COUNT;
        x = args.x + i * Math.cos(angle);
        y = args.y + i * Math.sin(angle);
        line.push(new Point(x, y));
        columnDelta++;
      }
      return line;
    },
    fn : function(a, target, self, e) {
      var d;
      var p3x2;
      var zero;
      var p3_2;
      var point_2;
      var pos = this.fo(target, self, e);
      /** @type {number} */
      var scale = Math.sqrt(pos.x * pos.x + pos.y * pos.y);
      /** @type {number} */
      var dy = pos.x / scale;
      /** @type {number} */
      var i = pos.y / scale;
      var c = this.fa(target, self);
      var r = this.fa(self, e);
      if (scale > this.ZERO_TOLERANCE) {
        if (this.fi(target, self, e)) {
          /** @type {number} */
          d = a * c;
          /** @type {number} */
          p3x2 = self.x - d * i;
          zero = self.y + d * dy;
          p3_2 = new Point(p3x2, zero, self.spatialReference);
          /** @type {number} */
          d = a * r;
          p3x2 = self.x + d * i;
          /** @type {number} */
          zero = self.y - d * dy;
          point_2 = new Point(p3x2, zero, self.spatialReference);
        } else {
          /** @type {number} */
          d = a * c;
          p3x2 = self.x + d * i;
          /** @type {number} */
          zero = self.y - d * dy;
          p3_2 = new Point(p3x2, zero, self.spatialReference);
          /** @type {number} */
          d = a * r;
          /** @type {number} */
          p3x2 = self.x - d * i;
          zero = self.y + d * dy;
          point_2 = new Point(p3x2, zero, self.spatialReference);
        }
      } else {
        p3x2 = self.x + a * (target.x - self.x);
        zero = self.y + a * (target.y - self.y);
        p3_2 = new Point(p3x2, zero, self.spatialReference);
        p3x2 = self.x + a * (e.x - self.x);
        zero = self.y + a * (e.y - self.y);
        point_2 = new Point(p3x2, zero, self.spatialReference);
      }
      return [p3_2, point_2];
    },
    fo : function(result, e, o) {
      /** @type {number} */
      var h = result.x - e.x;
      /** @type {number} */
      var v = result.y - e.y;
      /** @type {number} */
      var df = Math.sqrt(h * h + v * v);
      /** @type {number} */
      h = h / df;
      /** @type {number} */
      v = v / df;
      /** @type {number} */
      var x = o.x - e.x;
      /** @type {number} */
      var y = o.y - e.y;
      /** @type {number} */
      var GRID_SPACE_SIZE = Math.sqrt(x * x + y * y);
      /** @type {number} */
      x = x / GRID_SPACE_SIZE;
      /** @type {number} */
      y = y / GRID_SPACE_SIZE;
      /** @type {number} */
      var offset = h + x;
      /** @type {number} */
      var s = v + y;
      return new Point(offset, s, e.spatialReference);
    },
    fp : function(val, o) {
      var y;
      var r;
      var p;
      var index;
      /** @type {number} */
      var l = 0;
      var p1;
      var passed = this.fq(o);
      /** @type {!Array} */
      var k = [passed];
      /** @type {number} */
      var i = 0;
      for (; i < o.length - 2;) {
        y = o[i];
        r = o[i + 1];
        p = o[i + 2];
        index = this.fn(val, y, r, p);
        /** @type {!Array<?>} */
        k = k.concat(index);
        i++;
      }
      var prop = this.fr(o);
      k.push(prop);
      /** @type {!Array} */
      var points = [];
      /** @type {number} */
      i = 0;
      for (; i < o.length - 1;) {
        y = o[i];
        r = o[i + 1];
        points.push(y);
        /** @type {number} */
        l = 0;
        for (; l < this.FITTING_COUNT;) {
          p1 = this.fk(l / this.FITTING_COUNT, y, k[i * 2], k[i * 2 + 1], r);
          points.push(p1);
          l++;
        }
        points.push(r);
        i++;
      }
      return points;
    },
    fq : function(input, time) {
      if (typeof time === "undefined" || time == null || time === undefined) {
        /** @type {number} */
        time = 0;
      }
      var box;
      var lengthSt;
      var height;
      var length;
      var i;
      var t;
      var s;
      var row;
      var h;
      var set_h;
      var scale;
      var ratio;
      var p3x2;
      var cornerOffset;
      var item = input[0];
      var b = input[1];
      var res = input[2];
      var result = this.fn(time, item, b, res);
      var p = result[0];
      var x = this.fo(item, b, res);
      /** @type {number} */
      var r = Math.sqrt(x.x * x.x + x.y * x.y);
      if (r > this.ZERO_TOLERANCE) {
        box = this.fc(item, b);
        /** @type {number} */
        lengthSt = item.x - box.x;
        /** @type {number} */
        height = item.y - box.y;
        length = this.fa(item, b);
        /** @type {number} */
        i = 2 / length;
        /** @type {number} */
        t = -i * height;
        /** @type {number} */
        s = i * lengthSt;
        /** @type {number} */
        row = t * t - s * s;
        /** @type {number} */
        h = 2 * t * s;
        /** @type {number} */
        set_h = s * s - t * t;
        /** @type {number} */
        scale = p.x - box.x;
        /** @type {number} */
        ratio = p.y - box.y;
        p3x2 = box.x + row * scale + h * ratio;
        cornerOffset = box.y + h * scale + set_h * ratio;
      } else {
        p3x2 = item.x + time * (b.x - item.x);
        cornerOffset = item.y + time * (b.y - item.y);
      }
      return new Point(p3x2, cornerOffset, item.spatialReference);
    },
    fr : function(n, d) {
      if (typeof d === "undefined" || d == null || d === undefined) {
        /** @type {number} */
        d = 0;
      }
      var self;
      var l;
      var invDet;
      var shape;
      var u;
      var t;
      var s;
      var delta;
      var i;
      var y_size_min;
      var j;
      var scale;
      var p3x2;
      var cornerOffset;
      var dims = n.length;
      var c = n[dims - 3];
      var v = n[dims - 2];
      var e = n[dims - 1];
      var value = this.fn(d, c, v, e);
      var target = value[1];
      var b = this.fo(c, v, e);
      /** @type {number} */
      var r = Math.sqrt(b.x * b.x + b.y * b.y);
      if (r > this.ZERO_TOLERANCE) {
        self = this.fc(v, e);
        /** @type {number} */
        l = e.x - self.x;
        /** @type {number} */
        invDet = e.y - self.y;
        shape = this.fa(v, e);
        /** @type {number} */
        u = 2 / shape;
        /** @type {number} */
        t = -u * invDet;
        /** @type {number} */
        s = u * l;
        /** @type {number} */
        delta = t * t - s * s;
        /** @type {number} */
        i = 2 * t * s;
        /** @type {number} */
        y_size_min = s * s - t * t;
        /** @type {number} */
        j = target.x - self.x;
        /** @type {number} */
        scale = target.y - self.y;
        p3x2 = self.x + delta * j + i * scale;
        cornerOffset = self.y + i * j + y_size_min * scale;
      } else {
        p3x2 = e.x + d * (v.x - e.x);
        cornerOffset = e.y + d * (v.y - e.y);
      }
      return new Point(p3x2, cornerOffset, e.spatialReference);
    },
    fs : function(options) {
      var x;
      var lat;
      /** @type {number} */
      var p = 0;
      var c;
      var g;
      var t;
      if (options.length <= 2) {
        return options;
      }
      /** @type {!Array} */
      var path = [];
      /** @type {number} */
      var i = options.length - 1;
      /** @type {number} */
      var m = 0;
      for (; m <= 1;) {
        /** @type {number} */
        x = 0;
        /** @type {number} */
        lat = 0;
        /** @type {number} */
        p = 0;
        for (; p <= i;) {
          c = this.ft(i, p);
          /** @type {number} */
          g = Math.pow(m, p);
          /** @type {number} */
          t = Math.pow(1 - m, i - p);
          /** @type {number} */
          x = x + c * g * t * options[p].x;
          /** @type {number} */
          lat = lat + c * g * t * options[p].y;
          p++;
        }
        path.push(new Point(x, lat, options[0].spatialReference));
        /** @type {number} */
        m = m + 0.01;
      }
      path.push(options[i]);
      return path;
    },
    ft : function(d, c) {
      return this.fu(d) / (this.fu(c) * this.fu(d - c));
    },
    fu : function(lC) {
      if (lC <= 1) {
        return 1;
      }
      if (lC == 2) {
        return 2;
      }
      if (lC == 3) {
        return 6;
      }
      if (lC == 4) {
        return 24;
      }
      if (lC == 5) {
        return 120;
      }
      /** @type {number} */
      var headerScore = 1;
      /** @type {number} */
      var HeaderContentBonusMultiplier = 1;
      for (; HeaderContentBonusMultiplier <= lC;) {
        /** @type {number} */
        headerScore = headerScore * HeaderContentBonusMultiplier;
        HeaderContentBonusMultiplier++;
      }
      return headerScore;
    },
    fv : function(array) {
      var kb;
      var x;
      var y;
      /** @type {number} */
      var i = 0;
      var k;
      if (array.length <= 2) {
        return array;
      }
      /** @type {number} */
      var n = 2;
      /** @type {!Array} */
      var map = [];
      /** @type {number} */
      var tableCellWidth = array.length - n - 1;
      map.push(array[0]);
      /** @type {number} */
      var tailOldPos = 0;
      for (; tailOldPos <= tableCellWidth;) {
        /** @type {number} */
        kb = 0;
        for (; kb <= 1;) {
          /** @type {number} */
          x = 0;
          /** @type {number} */
          y = 0;
          /** @type {number} */
          i = 0;
          for (; i <= n;) {
            k = this.fw(i, kb);
            /** @type {number} */
            x = x + k * array[tailOldPos + i].x;
            /** @type {number} */
            y = y + k * array[tailOldPos + i].y;
            i++;
          }
          map.push(new Point(x, y));
          /** @type {number} */
          kb = kb + 0.05;
        }
        tailOldPos++;
      }
      map.push(array[array.length - 1]);
      return map;
    },
    fw : function(sid, val) {
      if (sid == 0) {
        return Math.pow(val - 1, 2) / 2;
      }
      if (sid == 1) {
        return (-2 * Math.pow(val, 2) + 2 * val + 1) / 2;
      }
      if (sid == 2) {
        return Math.pow(val, 2) / 2;
      }
      return 0;
    }
  };
};

function Point(x,y,sr){
  return {
    spatialReference: {wkid: 102100},
    type: "point",
    x: x,
    y: y,
  }
}


export default comfun;