import service from "../utils/request";
import common from "./common.js";
import login from "./login.js"; // 登录
import videoManage from "./videoManage"; //视频管理
import videoApproval from "./videoApproval"; //视频管理
import tongjifenxi from "./tongjifenxi"; //统计分析
const apiMap = {
  common,
  login,
  videoManage,
  videoApproval,
  tongjifenxi
};

/*

interface $apiParmas {
  urlMap: string // 接口配置 common.domain
  baseUrl: string // 基础路径
  headers: Array | Object // 请求头配置
  otherConfig： Object // 其他配置信息 详见axios官方文档
}

*/

export default Vue => {
  Object.assign(Vue.prototype, {
    $api(urlMap, baseUrl, headers, otherConfig = {}) {
      let urlConfig = urlMap.split(".").reduce((res, val) => res[val], apiMap);
      if (!baseUrl) {
        baseUrl = this.VUE_APP_BASE_API;
      }
      return {
        invoke: params => {
          // eslint-disable-next-line no-undef
          if (!urlConfig) return Promise.reject(`未定义[${urlMap}]接口`);
          else {
            let [method, url] = urlConfig.split(":");

            let requestConfig = Object.assign(
              { method, url },
              method === "get" ? { params } : { data: params },
              baseUrl && { url: `${baseUrl}${url}` },
              headers && { headers },
              otherConfig
            );

            return service(requestConfig);
          }
        }
      };
    }
  });
};
