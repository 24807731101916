import { getPowerMenus } from "@/api/authority.js";

function retunSysmodel(url = "") {
  if (url.includes("*")) return url.split("*").pop();
  return url.split("/").pop();
}

function setMenu({
  // MODULENAME: menuName,
  MODULETITLE: menuName,
  PICURL: icon,
  ACCESSURL: navigateUrl,
  PK_MODULEID: moduleid,
  children = [],
  URLTYPE: URLTYPE
}) {
  let sysmodel =
    children.length && !navigateUrl
      ? children.map(({ ACCESSURL }) => retunSysmodel(ACCESSURL))
      : retunSysmodel(navigateUrl);
  if (navigateUrl && navigateUrl.includes("*"))
    navigateUrl = "/index/leftMenuIndex/";
  return Object.assign(
    {
      menuName,
      icon,
      sysmodel,
      moduleid,
      moduleName: sysmodel,
      URLTYPE
    },
    !!children.length && { children: children.map(item => setMenu(item)) },
    navigateUrl && { navigateUrl }
  );
}

/* 收集所有权限菜单sysmodle */
function collectSysmodle(data = [], total = []) {
  data.forEach(({ sysmodel, children = [] }) => {
    total.push(sysmodel);
    if (children.length) collectSysmodle(children, total);
  });
  return total.flat();
}

function setMenuLeft(data, sysmodel) {
  return data.map(
    ({
      PICURL: icon,
      // DESCRIPTION: menuid,
      // MODULENAME: menuname,
      MODULETITLE: menuname,
      ACCESSURL: navigateurl,
      PK_MODULEID: menuid,
      children = []
    }) =>
      Object.assign(
        {
          icon,
          menuid,
          menuname,
          menutitle: menuname,
          sysmodel,
          children: setMenuLeft(children, sysmodel)
        },
        navigateurl && { navigateurl }
      )
  );
}

function setSysmodel(data, _sysmodel) {
  return data.map(({ sysmodel, datalistchildren = [], ...item }) => ({
    sysmodel: _sysmodel,
    ...item,
    datalistchildren: datalistchildren.length
      ? setSysmodel(datalistchildren, _sysmodel)
      : datalistchildren
  }));
}

function collectLeftMenuData(data, res = {}) {
  for (let index = 0; index < data.length; index++) {
    let item = data[index];
    let { ACCESSURL, children = [] } = item;

    if (children.length) {
      /* 需要收集侧边数据 */
      if (/left\*/.test(ACCESSURL)) {
        let sysmodel = /\*(.*)$/.exec(ACCESSURL)[1];
        res[sysmodel] = setMenuLeft(children, sysmodel);
      } else {
        collectLeftMenuData(children, res);
      }
    }
  }

  return res;
}

const menus = {
  state: {
    yjTopMenuData: [],
    yjLeftMenuData: {},
    baseLeftMenu: [] //获取基本信息左侧菜单接口返回的数据
  },
  getters: {
    yjAllTopMenus: state => collectSysmodle(state.yjTopMenuData)
  },
  mutations: {
    updateYjTopMenus(state, payload) {
      state.yjTopMenuData = setMenu({ children: payload }).children || [];
      state.yjLeftMenuData = collectLeftMenuData(payload);
      // console.log(state.yjLeftMenuData);
      if (state.yjLeftMenuData.baseInfo) {
        state.baseLeftMenu = state.yjLeftMenuData.baseInfo[0].children;
      }
    },
    updateMenus(state, val) {
      if (val.data && val.data.length > 0) {
        // let arr = [state.yjLeftMenuData.baseInfo[0].children[0]];
        let arr = [];
        arr.push(...state.baseLeftMenu);
        arr.push(...val.data);
        state.yjLeftMenuData.baseInfo[0].children = arr;
        state.yjTopMenuData.map(e => {
          if (e.sysmodel == "baseInfo") {
            e.children[0].children = arr;
          }
        });
      }
      // console.log(state.yjLeftMenuData);
      // console.log(state.yjTopMenuData);
    },
    updateQdMenus(state, val) {
      if (val.data && val.data.length > 0) {
        let arr = [];
        arr.push(...[]);
        arr.push(...val.data);
        state.yjLeftMenuData.qiangdufenxi[0].children = arr;
        state.yjTopMenuData.map(e => {
          if (e.moduleid == val.tongjiId) {
            e.children[0].children.map(q => {
              if (q.moduleName == "qiangdufenxi") {
                q.children[0].children = arr;
              }
            });
          }
        });
      }
    },
    updateShebeiQdMenus(state, val) {
      if (val.data && val.data.length > 0) {
        let arr = [];
        arr.push(...[]);
        arr.push(...val.data);
        state.yjLeftMenuData.qiangdufenxi[1] = {
          icon: "",
          menuid: "",
          menuname: "生产设备碳强度",
          menutitle: "生产设备碳强度",
          navigateurl: "qiangdufenxi",
          sysmodel: "qiangdufenxi",
          children: arr
        };
        state.yjTopMenuData.map(e => {
          if (e.moduleid == val.tongjiId) {
            e.children[0].children.map(q => {
              if (q.moduleName == "qiangdufenxi") {
                q.children[1].children = arr;
              }
            });
          }
        });
      }
    },
    updateTongjiTopMenus(state, val) {
      let initArr = [];
      if (val.data && val.data.length > 0) {
        state.yjTopMenuData.map(e => {
          if (e.moduleid == val.tongjiId) {
            initArr = e.children[0].children;
            let newArr = [...initArr, ...val.data];
            e.children[0].children = newArr;
          }
        });
      }
    }
  },
  actions: {
    async getPowerMenus({ commit }, val) {
      return new Promise((resolve, reject) => {
        return getPowerMenus(val).then(({ data }) => {
          //console.log(data);
          commit("updateYjTopMenus", data.data);
          resolve(data);
        });
      });
      // return getPowerMenus().then(({ data }) => {
      //   commit('updateYjTopMenus', data);
      // })
    }
  }
};

export default menus;
