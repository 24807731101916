import { CLOCKVIDEO } from "@/config/interface.config.js";
export default {
  getSdlVideoByList: `post:/${CLOCKVIDEO}/sysSdlVideo/getSdlVideoByList`, //视频管理列表
  getSdlVideoById: `get:/${CLOCKVIDEO}/sysSdlVideo/getSdlVideoById`, //主键查询视频信息
  saveSdlVideo: `post:/${CLOCKVIDEO}/sysSdlVideo/saveSdlVideo`, // 上传视频文件
  deleteSdlVideo: `get:/${CLOCKVIDEO}/sysSdlVideo/deleteSdlVideo`, //删除视频信息
  updateSdlVideo: `post:/${CLOCKVIDEO}/sysSdlVideo/updateSdlVideo`, //修改视频信息，只修改基本信息，不修改视频
  initializeSdlVideo: `post:/${CLOCKVIDEO}/sysSdlVideo/initializeSdlVideo`, //初始化视频（视频切片）
  getUserVideoAuthList: `post:/${CLOCKVIDEO}/sysSdlVideo/getUserVideoAuthList`, //用户授权列表
  getVideoWithAuthList: `post:/${CLOCKVIDEO}/sysSdlVideo/getVideoWithAuthList`, //查看权限和权限授权
  deletevideoUserLimits: `get:/${CLOCKVIDEO}/sysSdlVideo/deletevideoUserLimits`, //查看权限和权限授权
  saveVideoUserLimitsBatch: `post:/${CLOCKVIDEO}/sysSdlVideo/saveVideoUserLimitsBatch`, //批量保存直接授权的数据
  saveVideoUserLimits: `post:/${CLOCKVIDEO}/sysSdlVideo/saveVideoUserLimits`, //单条保存直接授权的数据
  getQrCodeVideoList: `post:/${CLOCKVIDEO}/sysSdlVideo/getQrCodeVideoList`, //用户授权列表
  getUserApplyById: `get:/${CLOCKVIDEO}/sysSdlVideo/getUserApplyById`, //用户申请的详情
  getDirectAuthById: `get:/${CLOCKVIDEO}/sysSdlVideo/getDirectAuthById`, //直接授权的详情
  getVideoTypeTree: `get:/${CLOCKVIDEO}/sysSdlVideo/getVideoTypeTree`, //视频类型树形数据
  getSdlVideoByListNoFileUrl: `post:/${CLOCKVIDEO}/sysSdlVideo/getSdlVideoByListNoFileUrl`, //列表查询基本信息
  getVideoTypeFirstlevel: `get:/${CLOCKVIDEO}/sysSdlVideo/getVideoTypeFirstlevel`, //视频类型
  getSdlVideoAuthByList: `post:/${CLOCKVIDEO}/sysSdlVideo/getSdlVideoAuthByList`, //
  saveSdlVideoVisits: `post:/${CLOCKVIDEO}/sysSdlVideo/saveSdlVideoVisits`, //
  getAllVideoWithAuthList: `post:/${CLOCKVIDEO}/sysSdlVideo/getAllVideoWithAuthList` //
  
};
