var Windy = function Windy(params) {
    var mask;
    var MIN_VELOCITY_INTENSITY = params.minVelocity || 0; // velocity at which particle intensity is minimum (m/s)
    var MAX_VELOCITY_INTENSITY = params.maxVelocity || 10; // velocity at which particle intensity is maximum (m/s)
    var VELOCITY_SCALE = (params.velocityScale || 0.005) * (Math.pow(window.devicePixelRatio, 1 / 3) || 1); // scale for wind velocity (completely arbitrary--this value looks nice)
    var MAX_PARTICLE_AGE = params.particleAge || 90; // 粒子长度 max number of frames a particle is drawn before regeneration
    var PARTICLE_LINE_WIDTH = params.lineWidth || 1; // 线条宽度  line width of a drawn particle
    var PARTICLE_MULTIPLIER = params.particleMultiplier || 1 / 300; // 粒子密度 particle count scalar (completely arbitrary--this values looks nice)
    var PARTICLE_REDUCTION = Math.pow(window.devicePixelRatio, 1 / 3) || 1.6; // multiply particle count for mobiles by this amount
    var FRAME_RATE = params.frameRate || 15,
        FRAME_TIME = 1000 / FRAME_RATE; //每秒所需帧数 desired frames per second
    var SPEED_RATE = params.speedRate || 1;
    var CRS = params.crs;

    //	var defaulColorScale = ["rgb(36,104, 180)", "rgb(60,157, 194)", "rgb(128,205,193 )", "rgb(151,218,168 )", "rgb(198,231,181)", "rgb(238,247,217)", "rgb(255,238,159)", "rgb(252,217,125)", "rgb(255,182,100)", "rgb(252,150,75)", "rgb(250,112,52)", "rgb(245,64,32)", "rgb(237,45,28)", "rgb(220,24,32)", "rgb(180,0,35)"];
    //	var defaulColorScale = ["rgb(0,153,255)", "rgb(0,103,255)", "rgb(47,154,0 )", "rgb(38,255,0 )", "rgb(210,254,9)", "rgb(252,254,0)", "rgb(243,212,47)", "rgb(251,155,3)","rgb(222,196,195)", "rgb(204,149,145)", "rgb(144,75,69)", "rgb(255,51,51)", "rgb(210,57,58)", "rgb(178,55,55)", "rgb(178,51,178)", "rgb(222,55,225)","rgb(242,55,255)"];
    var defaulColorScale = ["rgb(82,71, 141)", "rgb(85,78, 177)", "rgb(80,87,184)", "rgb(67,105,196)", "rgb(64,180,160)", "rgb(78,193,103)", "rgb(104,209,79)", "rgb(157,211,68)", "rgb(220,234,55)", "rgb(234,164,62)", "rgb(217,66,114)", "rgb(147,23,78)", "rgb(43,0,1)"];
    var colorScale = params.colorScale || defaulColorScale;
    var tooltip = params.tooltip;

    var NULL_WIND_VECTOR = [NaN, NaN, null]; // singleton for no wind in the form: [u, v, magnitude]

    var builder;
    var grid;
    var gridData = params.data;
    var date;
    var λ0, φ0, Δλ, Δφ, ni, nj;

    var setData = function setData(data) {
        gridData = data;
    };

    // interpolation for vectors like wind (u,v,m)
    var bilinearInterpolateVector = function bilinearInterpolateVector(x, y, g00, g10, g01, g11) {
        var rx = 1 - x;
        var ry = 1 - y;
        var a = rx * ry,
            b = x * ry,
            c = rx * y,
            d = x * y;
        var u = g00[0] * a + g10[0] * b + g01[0] * c + g11[0] * d;
        var v = g00[1] * a + g10[1] * b + g01[1] * c + g11[1] * d;
        return [u, v, Math.sqrt(u * u + v * v)];
    };

    var createWindBuilder = function createWindBuilder(uComp, vComp) {
        var uData = uComp.data,
            vData = vComp.data;
        return {
            header: uComp.header,
            //recipe: recipeFor("wind-" + uComp.header.surface1Value),
            data: function data(i) {
                if (uData[i] > 1000 || vData[i] > 1000) {
                    uData[i] = 0;
                    vData[i] = 0;
                }
                return [uData[i], vData[i]];
            },
            interpolate: bilinearInterpolateVector
        };
    };

    var createBuilder = function createBuilder(data) {
        var uComp = null,
            vComp = null,
            scalar = null;

        data.forEach(function (record) {
            switch (record.header.parameterCategory + "," + record.header.parameterNumber) {
                case "1,2":
                case "2,2":
                    uComp = record;
                    break;
                case "1,3":
                case "2,3":
                    vComp = record;
                    break;
                default:
                    scalar = record;
            }
        });

        return createWindBuilder(uComp, vComp);
    };

    var buildGrid = function buildGrid(data, callback) {

        builder = createBuilder(data);
        var header = builder.header;

        λ0 = header.lo1;
        φ0 = header.la1; // the grid's origin (e.g., 0.0E, 90.0N)
        if (φ0 < header.la2) φ0 = header.la2;
        Δλ = header.dx;
        Δφ = header.dy; // distance between grid points (e.g., 2.5 deg lon, 2.5 deg lat)

        ni = header.nx;
        nj = header.ny; // number of grid points W-E and N-S (e.g., 144 x 73)

        date = new Date(header.refTime);
        date.setHours(date.getHours() + header.forecastTime);

        // Scan mode 0 assumed. Longitude increases from λ0, and latitude decreases from φ0.
        // http://www.nco.ncep.noaa.gov/pmb/docs/grib2/grib2_table3-4.shtml
        grid = [];
        var p = 0;
        var isContinuous = Math.floor(ni * Δλ) >= 360;

        for (var j = 0; j < nj; j++) {
            var row = [];
            for (var i = 0; i < ni; i++ , p++) {
                row[i] = builder.data(p);
            }
            if (isContinuous) {
                // For wrapped grids, duplicate first column as last column to simplify interpolation logic
                row.push(row[0]);
            }
            grid[j] = row;
        }

        callback({
            date: date,
            interpolate: interpolate
        });
    };

    /**
     * Get interpolated grid value from Lon/Lat position
     * @param λ {Float} Longitude
     * @param φ {Float} Latitude
     * @returns {Object}
     */
    var interpolate = function interpolate(λ, φ) {

        if (!grid) return null;

        var i = floorMod(λ - λ0, 360) / Δλ; // calculate longitude index in wrapped range [0, 360)
        var j = (φ0 - φ) / Δφ; // calculate latitude index in direction +90 to -90

        var fi = Math.floor(i),
            ci = fi + 1;
        var fj = Math.floor(j),
            cj = fj + 1;

        var row;
        if (row = grid[fj]) {
            var g00 = row[fi];
            var g10 = row[ci];
            if (isValue(g00) && isValue(g10) && (row = grid[cj])) {
                var g01 = row[fi];
                var g11 = row[ci];
                if (isValue(g01) && isValue(g11)) {
                    // All four points found, so interpolate the value.
                    return builder.interpolate(i - fi, j - fj, g00, g10, g01, g11);
                }
            }
        }
        return null;
    };

    /**
     * @returns {Boolean} true if the specified value is not null and not undefined.
     */
    var isValue = function isValue(x) {
        return x !== null && x !== undefined;
    };

    /**
     * @returns {Number} returns remainder of floored division, i.e., floor(a / n). Useful for consistent modulo
     *          of negative numbers. See http://en.wikipedia.org/wiki/Modulo_operation.
     */
    var floorMod = function floorMod(a, n) {
        return a - n * Math.floor(a / n);
    };

    /**
     * @returns {Number} the value x clamped to the range [low, high].
     */
    var clamp = function clamp(x, range) {
        return Math.max(range[0], Math.min(x, range[1]));
    };

    /**
     * @returns {Boolean} true if agent is probably a mobile device. Don't really care if this is accurate.
     */
    var isMobile = function isMobile() {
        return (/android|blackberry|iemobile|ipad|iphone|ipod|opera mini|webos/i.test(navigator.userAgent)
        );
    };

    /**
     * Calculate distortion of the wind vector caused by the shape of the projection at point (x, y). The wind
     * vector is modified in place and returned by this function.
     */
    var distort = function distort(projection, λ, φ, x, y, scale, wind, windy) {
        var u = wind[0] * scale;
        var v = wind[1] * scale;
        var d = distortion(projection, λ, φ, x, y, windy);

        // Scale distortion vectors by u and v, then add.
        wind[0] = d[0] * u + d[2] * v;
        wind[1] = d[1] * u + d[3] * v;
        return wind;
    };

    var distortion = function distortion(projection, λ, φ, x, y, windy) {
        var τ = 2 * Math.PI;
        var H = Math.pow(10, -5.2);
        //qlupdate 解决4326坐标系的风场计算
        if (CRS.indexOf('4326') > -1) {
	    	var H = 5;
        }
        var hλ = λ < 0 ? H : -H;
        var hφ = φ < 0 ? H : -H;

        var pλ = project(φ, λ + hλ, windy);
        var pφ = project(φ + hφ, λ, windy);

        // Meridian scale factor (see Snyder, equation 4-3), where R = 1. This handles issue where length of 1º λ
        // changes depending on φ. Without this, there is a pinching effect at the poles.
        var k = Math.cos(φ / 360 * τ);
        return [(pλ[0] - x) / hλ / k, (pλ[1] - y) / hλ / k, (pφ[0] - x) / hφ, (pφ[1] - y) / hφ];
    };

    var createField = function createField(columns, bounds, callback) {

        /**
         * @returns {Array} wind vector [u, v, magnitude] at the point (x, y), or [NaN, NaN, null] if wind
         *          is undefined at that point.
         */
        function field(x, y) {
            var column = columns[Math.round(x)];
            return column && column[Math.round(y)] || NULL_WIND_VECTOR;
        }

        // Frees the massive "columns" array for GC. Without this, the array is leaked (in Chrome) each time a new
        // field is interpolated because the field closure's context is leaked, for reasons that defy explanation.
        field.release = function () {
            columns = [];
        };

        field.randomize = function (o) {
            // UNDONE: this method is terrible
            var x, y;
            var safetyNet = 0;
            do {
                x = Math.round(Math.floor(Math.random() * bounds.width) + bounds.x);
                y = Math.round(Math.floor(Math.random() * bounds.height) + bounds.y);
            } while (field(x, y)[2] === null && safetyNet++ < 30);
            o.x = x;
            o.y = y;
            return o;
        };
        field.overlay = mask.imageData;

        callback(bounds, field);
    };

    var buildBounds = function buildBounds(bounds, width, height) {
        var upperLeft = bounds[0];
        var lowerRight = bounds[1];
        var x = Math.round(upperLeft[0]); //Math.max(Math.floor(upperLeft[0], 0), 0);
        var y = Math.max(Math.floor(upperLeft[1], 0), 0);
        var xMax = Math.min(Math.ceil(lowerRight[0], width), width - 1);
        var yMax = Math.min(Math.ceil(lowerRight[1], height), height - 1);
        return { x: x, y: y, xMax: width, yMax: yMax, width: width, height: height };
    };

    var deg2rad = function deg2rad(deg) {
        return deg / 180 * Math.PI;
    };

    var rad2deg = function rad2deg(ang) {
        return ang / (Math.PI / 180.0);
    };
/*
    var invert = function invert(x, y, windy) {
        var mapLonDelta = windy.east - windy.west;
        var worldMapRadius = windy.width / rad2deg(mapLonDelta) * 360 / (2 * Math.PI);
        var mapOffsetY = worldMapRadius / 2 * Math.log((1 + Math.sin(windy.south)) / (1 - Math.sin(windy.south)));
        var equatorY = windy.height + mapOffsetY;
        var a = (equatorY - y) / worldMapRadius;

        var lat = 180 / Math.PI * (2 * Math.atan(Math.exp(a)) - Math.PI / 2);
        var lon = rad2deg(windy.west) + x / windy.width * rad2deg(mapLonDelta);
        return [lon, lat];
    };
    */
    //qlupdate  解决4326坐标系的风场计算
    var invert = function invert(x, y, windy) {
        var mapLonDelta = windy.east - windy.west;
		var worldMapRadius = windy.width / rad2deg(mapLonDelta) * 360 / (2 * Math.PI);
		var mapOffsetY = worldMapRadius / 2 * Math.log((1 + Math.sin(windy.south)) / (1 - Math.sin(windy.south)));
		var equatorY = windy.height + mapOffsetY;
		var a = (equatorY - y) / worldMapRadius;
		var lat = 180 / Math.PI * (2 * Math.atan(Math.exp(a)) - Math.PI / 2);
        //qlupdate  解决4326坐标系的风场计算
        if (CRS.indexOf('4326') > -1) {
		  var mapLatDelta = windy.south - windy.north;
		  lat = rad2deg(windy.north) + y / windy.height * rad2deg(mapLatDelta);
        }
		var lon = rad2deg(windy.west) + x / windy.width * rad2deg(mapLonDelta);
		return [lon, lat];
	};

    var mercY = function mercY(lat) {
        return Math.log(Math.tan(lat / 2 + Math.PI / 4));
    };

    var project = function project(lat, lon, windy) {
        // both in radians, use deg2rad if neccessary
        var ymin = mercY(windy.south);
        var ymax = mercY(windy.north);
        var xFactor = windy.width / (windy.east - windy.west);
        var yFactor = windy.height / (ymax - ymin);

        var y = mercY(deg2rad(lat));
        var x = (deg2rad(lon) - windy.west) * xFactor;
        var y = (ymax - y) * yFactor; // y points south
        return [x, y];
    };

    function createMask(contextProject) {
        // Create a detached canvas, ask the model to define the mask polygon, then fill with an opaque color.

        var width = params.canvasOverlay.width,
            height = params.canvasOverlay.height;
        // width = 500; height = 400
        var canvas = document.createElement("canvas");
        canvas.setAttribute("width", width);
        canvas.setAttribute("height", height);
        if (!contextProject) {
            var context = canvas.getContext("2d");
        } else {
            var context = contextProject(canvas.getContext("2d"));
        }
        context.fillStyle = "rgba(0,0, 0, 0)";
        context.fill();
        var imageData = context.getImageData(0, 0, width, height);
        var data = imageData.data; // layout: [r, g, b, a, r, g, b, a, ...]
        return {
            imageData: imageData,
            isVisible: function isVisible(x, y) {
                var i = (y * width + x) * 4;
                return data[i + 3] > 0; // non-zero alpha means pixel is visible
            },
            set: function set(x, y, rgba) {
                var i = (y * width + x) * 4;
                data[i] = rgba[0];
                data[i + 1] = rgba[1];
                data[i + 2] = rgba[2];
                data[i + 3] = rgba[3];

                return this;
            }
        };
    }
    var interpolateField = function interpolateField(grid, bounds, extent, callback) {

        var projection = {};
        var mapArea = (extent.south - extent.north) * (extent.west - extent.east);
        var velocityScale = VELOCITY_SCALE * Math.pow(mapArea, 0.4);

        var columns = [];
        var x = bounds.x;
        mask = createMask();
        function interpolateColumn(x) {
            var column = [];
            for (var y = bounds.y; y <= bounds.yMax; y += 2) {
                var coord = invert(x, y, extent);
                if (coord) {
                    var λ = coord[0],
                        φ = coord[1];
                    if (isFinite(λ)) {
                        var wind = grid.interpolate(λ, φ);
                        if (wind) {
                            wind[0] = wind[0] * SPEED_RATE;
                            wind[1] = wind[1] * SPEED_RATE;
                            wind = distort(projection, λ, φ, x, y, velocityScale, wind, extent);
                            column[y + 1] = column[y] = wind;
                            var color = params.colorScalar(wind[2]);

                            mask.set(x, y, color).set(x + 1, y, color).set(x, y + 1, color).set(x + 1, y + 1, color);
                        }
                    }
                }
            }
            columns[x + 1] = columns[x] = column;
        }

        (function batchInterpolate() {
            var start = Date.now();
            while (x < bounds.width) {
                interpolateColumn(x);
                x += 2;
                if (Date.now() - start > 1000) {
                    //MAX_TASK_TIME) {
                    setTimeout(batchInterpolate, 25);
                    return;
                }
            }
            createField(columns, bounds, callback);

            //tooltip
            if (tooltip) {
                var tipDom = document.getElementsByClassName('mouseTooltip');
                if (tipDom.length > 0) {
                    tipDom[0].parentNode.removeChild(tipDom[0]);
                    //tipDom[0].remove();
                }
                let tipDiv = document.createElement('div');
                tipDiv.className = 'mouseTooltip';
                tipDiv.style.position = 'absolute';
                document.body.appendChild(tipDiv);

                params.canvas.onmousemove = function (e) {
                    var windpoint = columns[e.layerX][e.layerY];
                    e.speed = windpoint[2];
                    //e.direction = Math.atan(windpoint[1] / windpoint[0]);
                    e.directionLabel = getDirection(windpoint[0], windpoint[1]);
                    e.level = getLevel(windpoint[2]);
                    tipDiv.style.display = 'block';
                    tipDiv.style.left = e.clientX + 'px'
                    tipDiv.style.top = e.clientY + 'px';
                    tipDiv.innerHTML = tooltip(e);;

                }
                params.canvas.onmouseleave = function () {
                    tipDiv.style.display = 'none';
                }
            }
        })();
    };
    function drawOverlay(field) {
        if (!field) return;
        var ctx = params.canvasOverlay.getContext("2d");
        ctx.clearRect(0, 0, params.canvas.width, params.canvas.height);
        ctx.putImageData(field.overlay, 0, 0);
        ctx.globalAlpha = 0;
    }

    //计算风向
    function getDirection(u, v) {
        //var windDirectstd = [90, 180, 270, 360];
        var windDirectstd = [22.5, 67.5, 112.5, 157.5, 202.5, 247.5, 295.5, 360];
        var windlabeltstd = ['北', '东北', '东', '东南', '南', '西南', '西', '西北', '北'];
        v = 0 - v;  // 画布坐标方向与实际风向相反
        var direction = Math.abs(Math.atan(u / v)) * 180 / Math.PI;
        if (u > 0 && v > 0) {
            direction = 180 + direction;
        } else if (u > 0 && v < 0) {
            direction = 360 - direction;
        } else if (u < 0 && v < 0) {
            direction = direction;
        }
        else if (u < 0 && v > 0) {
            direction = 180 - direction;
        }
        direction = direction < 0 ? direction + 360 : direction;
        for (let i = 0; i < windDirectstd.length; i++) {
            const direct = windDirectstd[i];
            if (direction < direct) {
                return windlabeltstd[i] + '风';
            }
        }
        return '北风'
    }

    //计算风级
    function getLevel(speed) {
        var windstd = [1, 6, 12, 20, 19, 29, 39, 50, 62, 75, 89, 103]; // km/h
        for (let i = 0; i < windstd.length; i++) {
            var std = windstd[i];
            if (speed * 3.6 < std) {
                return i;
            }
        }
    }

    var animationLoop;
    var animate = function animate(bounds, field) {

        /* 		function windIntensityColorScale(min, max) {
                     colorScale.indexFor = function (m) {
                        // map velocity speed to a style
                        if(m <= 1){
                            return 0;
                        }else if(m <= 2){
                            return 1;
                        }else if(m <= 4){
                            return 2;
                        }else if(m <= 6){
                            return 3;
                        }else if(m <= 8){
                            return 4;
                        }else if(m <= 10){
                            return 5;
                        }else if(m <= 12){
                            return 6;
                        }else if(m <= 18){
                            return 7;
                        }else if(m <= 24){
                            return 8;
                        }else if(m <= 30){
                            return 9;
                        }else if(m <= 36){
                            return 10;
                        }else if(m <= 37){
                            return 11;
                        }else if(m <= 42){
                            return 12;
                        }else {
                            return 13;
                        }
        //				return Math.floor(m);
        //				return Math.max(0, Math.min(colorScale.length - 1, Math.round((m - min) / (max - min) * (colorScale.length - 1))));
                    };
                     return colorScale;
                } */
        function windIntensityColorScale(min, max) {

            colorScale.indexFor = function (m) {
                // map velocity speed to a style
                return Math.max(0, Math.min(colorScale.length - 1, Math.round((m - min) / (max - min) * (colorScale.length - 1))));
            };

            return colorScale;
        }
        var colorStyles = windIntensityColorScale(MIN_VELOCITY_INTENSITY, MAX_VELOCITY_INTENSITY);
        var buckets = colorStyles.map(function () {
            return [];
        });

        var particleCount = Math.round(bounds.width * bounds.height * PARTICLE_MULTIPLIER);
        if (isMobile()) {
            particleCount *= PARTICLE_REDUCTION;
        }

        var fadeFillStyle = "rgba(0, 0, 0, 0.9)";

        var particles = [];
        for (var i = 0; i < particleCount; i++) {
            particles.push(field.randomize({ age: Math.floor(Math.random() * MAX_PARTICLE_AGE) + 0 }));
        }

        function evolve() {
            buckets.forEach(function (bucket) {
                bucket.length = 0;
            });
            particles.forEach(function (particle) {
                if (particle.age > MAX_PARTICLE_AGE) {
                    field.randomize(particle).age = 0;
                }
                var x = particle.x;
                var y = particle.y;
                var v = field(x, y); // vector at current position
                var m = v[2];
                if (m === null) {
                    particle.age = MAX_PARTICLE_AGE; // particle has escaped the grid, never to return...
                } else {
                    var xt = x + v[0];
                    var yt = y + v[1];
                    if (field(xt, yt)[2] !== null) {
                        // Path from (x,y) to (xt,yt) is visible, so add this particle to the appropriate draw bucket.
                        particle.xt = xt;
                        particle.yt = yt;
                        try {
                            buckets[colorStyles.indexFor(m)].push(particle);
                        } catch (e) {
                            console.log(colorStyles.indexFor(m));
                        }
                    } else {
                        // Particle isn't visible, but it still moves through the field.
                        particle.x = xt;
                        particle.y = yt;
                    }
                }
                particle.age += 1;
            });
        }

        var g = params.canvas.getContext("2d");
        g.lineWidth = PARTICLE_LINE_WIDTH;
        g.fillStyle = fadeFillStyle;
        // g.globalAlpha = 1.2;

        function draw() {
            // Fade existing particle trails.
            var prev = "lighter";
            g.globalCompositeOperation = "destination-in";
            g.fillRect(bounds.x, bounds.y, bounds.width, bounds.height);
            g.globalCompositeOperation = prev;
            // g.globalAlpha = 1.5;

            // Draw new particle trails.
            buckets.forEach(function (bucket, i) {
                if (bucket.length > 0) {
                    g.beginPath();
                    g.strokeStyle = colorStyles[i];
                    bucket.forEach(function (particle) {
                        g.moveTo(particle.x, particle.y);
                        g.lineTo(particle.xt, particle.yt);
                        particle.x = particle.xt;
                        particle.y = particle.yt;
                    });
                    g.stroke();
                }
            });
        }

        var then = Date.now();
        (function frame() {
            animationLoop = requestAnimationFrame(frame);
            var now = Date.now();
            var delta = now - then;
            if (delta > FRAME_TIME) {
                then = now - delta % FRAME_TIME;
                evolve();
                draw();
            }
        })();
    };

    var start = function start(bounds, width, height, extent) {

        var mapBounds = {
            south: deg2rad(extent[0][1]),
            north: deg2rad(extent[1][1]),
            east: deg2rad(extent[1][0]),
            west: deg2rad(extent[0][0]),
            width: width,
            height: height
        };

        stop();

        // build grid
        buildGrid(gridData, function (grid) {
            // interpolateField
            interpolateField(grid, buildBounds(bounds, width, height), mapBounds, function (bounds, field) {
                // animate the canvas with random points
                windy.field = field;

                animate(bounds, field);
                drawOverlay(field);
            });
        });
    };

    var stop = function stop() {
        if (windy.field) windy.field.release();
        if (animationLoop) cancelAnimationFrame(animationLoop);
        mask = null;
        params.canvas.getContext('2d').clearRect(0, 0, params.canvasOverlay.width, params.canvasOverlay.height);
        params.canvasOverlay.getContext('2d').clearRect(0, 0, params.canvasOverlay.width, params.canvasOverlay.height);
    };

    var windy = {
        params: params,
        start: start,
        stop: stop,
        createField: createField,
        interpolatePoint: interpolate,
        setData: setData
    };

    return windy;
};


export default Windy