import * as turf from "@turf/turf"
import bbox from '@turf/bbox';

//反距离插值分析
//基于turf,不重复造轮子
function lidw(options) {
  let points = options.gra;
  let cellSize = options.cellSize;
  let property = options.zField;
  let units = options.units;
  let isDeal = options.isDeal ? options.isDeal : true;
  let weight = options.weight ? options.weight : 1;
  let mask = options.mask;
  let isRaster = options.isRaster;
  let gridType = options.gridType;
  let grid, newgrid = {};

  if (isDeal) {
    createGrid();
    interpolateGrid()
  }

  //生成网格
  function createGrid() {
    let box = mask ? bbox(mask) : bbox(points);
    let gridOptions = {
      gridType: gridType,
      property: property,
      units: units
    }
    switch (gridType) {
      case 'point':
      case 'points':
        grid = turf.pointGrid(box, cellSize, gridOptions);
        break;
      case 'square':
      case 'squares':
        grid = turf.squareGrid(box, cellSize, gridOptions);
        break;
      case 'hex':
      case 'hexes':
        grid = turf.hexGrid(box, cellSize, gridOptions);
        break;
      case 'triangle':
      case 'triangles':
        grid = turf.triangleGrid(box, cellSize, gridOptions);
        break;
      default:
        throw new Error('invalid gridType');
    }

    //过滤掩膜之外的点
    if (mask) { 
      let newgrid = [];
      turf.featureEach(grid, function (gridFeature) {
        var gridPoint = (gridType === 'points') ? gridFeature : turf.centroid(gridFeature);
        let x = gridPoint.geometry.coordinates[0];
        let y = gridPoint.geometry.coordinates[1];
        let polygon = mask.features[0].geometry;
        let isin = turf.booleanPointInPolygon([x, y], polygon);
        if (isin) newgrid.push(gridFeature);
      })
      grid = turf.featureCollection(newgrid);
    }

  }

  //计算网格值
  function interpolateGrid() {
    var results = [];
    turf.featureEach(grid, function (gridFeature) {
      var gridPoint = (gridType === 'points') ? gridFeature : turf.centroid(gridFeature);
      var w = interpolate(gridPoint, points);
      var zw = w[0];
      var sw = w[1];

      // write interpolated value for each grid point
      var newFeature = turf.clone(gridFeature);
      newFeature.properties[property] = zw / sw;
      results.push(newFeature);
    });
    newgrid = turf.featureCollection(results);
  }

  //计算单个点
  function interpolate(gridPoint) {
    var zw = 0;
    var sw = 0;
    if (gridPoint.features) gridPoint = gridPoint.features[0];
    turf.featureEach(points, function (point) {
      var d = turf.distance(gridPoint, point, {
        units: units
      });
      var zValue;
      // property has priority for zValue, fallbacks to 3rd coordinate from geometry
      if (property !== undefined) zValue = point.properties[property];
      if (zValue === undefined) zValue = point.geometry.coordinates[2];
      if (zValue === undefined) throw new Error('zValue is missing');
      if (d === 0) zw = zValue;
      var w = 1.0 / Math.pow(d, weight);
      sw += w;
      zw += w * zValue;
    });
    return [zw, sw];
  }

  function interpolateField() {
    let canvas = document.createElement('canvas');

  }

  newgrid.interpolateSingle = interpolate;

  return newgrid;
}

export default lidw