import { Map, View, Overlay } from "ol";
import XYZ from "ol/source/XYZ";
import { OSM, TileArcGISRest, ImageArcGISRest, TileImage, Vector as VectorSource, WMTS, ImageStatic } from "ol/source";
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import TileWMS from 'ol/source/TileWMS';
import ImageWMS from 'ol/source/ImageWMS';
import TileGrid from "ol/tilegrid/TileGrid"
import * as proj from "ol/proj";
import { Tile as TileLayer, Vector as VectorLayer, Image as ImageLayer, Heatmap } from 'ol/layer';
import { Circle as CircleStyle, Fill, Stroke, Style, Icon, Text } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON.js';
import EsriJSON from 'ol/format/EsriJSON';
import * as extent from 'ol/extent';
import DragPan from 'ol/interaction/DragPan';
import comFun from './comfun';
import attackArrow from './attack_arrow';
import ltween from './ltween';

// 获取地图交互事件获取的动态坐标
// 返回根据规则生成对应的图形
// 支持以下样式：
// point line polygon rectangle triangle circle ellipse arrow 基础图形
// freehandline 自由线
// freehandpolygon 自由多边形
// freehandarrow 自由箭头 
// curve 单曲线
// beziercurve 贝齐尔曲线 
// bezierpolygon 贝齐尔曲面 -集结地
// tailedsquadcombat 燕尾箭头
function ldraw(options) {
  let type = options.type;
  if (type == "LineString") type = 'line';
  if (type == "Point") type = 'point';
  let map = options.map; 

  function createLayer(geoType, coordinates) {
    let feature;
    let style;
    switch (geoType) {
      case "Point":
        feature = coordinates[0];
        style = new Style({
          image: new CircleStyle({
            radius: 5,
            fill: new Fill({ color: 'red' }),
            stroke: new Stroke({ color: 'orange', width: 1 })
          })
        })
        break;
      case "LineString":
        feature = coordinates;
        style = new Style({
          stroke: new Stroke({
            color: 'red',
            width: 1
          })
        })
        break;
      case "Polygon":
        feature = [coordinates];
        style = new Style({
          fill: new Fill({
            color: 'rgba(255, 0, 0, 0.3)'
          }),
          stroke: new Stroke({
            color: 'red',
            width: 1
          })
        })
        break;
      default:
        break;
    }
    let geojson = {
      'type': 'FeatureCollection',
      'crs': {
        'type': 'name',
        'properties': {
          'name': 'EPSG:3857'
        }
      },
      'features': [
        {
          'type': 'Feature',
          'geometry': {
            'type': geoType,
            'coordinates': feature
          },
          "properties": {
          }
        }
      ]
    }
    let vectorSource = new VectorSource({
      features: (new GeoJSON()).readFeatures(geojson, {
        //dataProjection: 'EPSG:4326',    // 设定JSON数据使用的坐标系
        featureProjection: 'EPSG:3857' // 设定当前地图使用的feature的坐标系
      })
    });
    //添加图层
    var vectorLayer = new VectorLayer({
      id: "drawlayer_" + Date.parse(new Date()),
      source: vectorSource,
      style: style,
    });
    map.addLayer(vectorLayer);

    return vectorLayer;
  }
 
  let drawer = {
    coordinates: [],
    points: [],
    isTmp: false,
    feature: null,
    layer: null,
    callback: options.callback,
    isActive: false,
    eventClick: null,
    eventDbclick: null,
    eventMove: null,
    eventDown: null,
    eventUp: null,
    //计算目标图形坐标
    deal() {
      //数组尾部去重
      this.unRepetity();
      switch (type) {
        case 'circle':
          return design.circle(this.points);
        case 'beziercurve':
          return design.beziercurve(this.points);
        case 'bezierpolygon':
          return design.bezierpolygon(this.points);
        case 'arrow':
          return design.arrow(this.points);
        case 'bezierarrow':
          return design.bezierarrow(this.points);
        case 'tailedsquadcombat':
          return design.tailedsquadcombat(this.points);
          break;
        default:
          return this.points;
      }
    },
    //更新坐标集合
    udpate(coordinate) {
      if (this.isTmp) {
        //替换最后一个临时点
        this.points.splice(this.points.length - 1, 1, coordinate);
        this.isTmp = false;
      } else {
        this.points.push(coordinate);
      }
      //点：一个结束
      if (type == "point") {
        this.coordinates = this.deal();
        this.render();
        this.stop();
        return;
      }
      //圆形：两个结束
      if ((type == "circle" || type == "arrow") && this.points.length > 1) {
        this.coordinates = this.deal();
        this.render();
        if (this.points.length = 2) {
          this.stop();
          return;
        }
      }
      if (this.points.length > 1) {
        this.coordinates = this.deal();
        this.render();
      }
    },
    //构建临时坐标集合
    udpateTmp(coordinate) {
      if (this.points.length == 0) {
        this.points.push(coordinate);
        this.isTmp = true;
        return;
      }
      if (this.isTmp) {
        this.points.splice(this.points.length - 1, 1, coordinate);
      } else {
        this.points.push(coordinate);
      }
      this.isTmp = true;
      if (this.points.length > 1 || type == "point") {
        this.coordinates = this.deal();
        this.render();
      }

    },
    //启动
    start() {
      this.bindEvent();
      if (type.indexOf('free') > -1) {
        this.disablePan();
      }
    },
    //停止并重置
    stop() {
      this.removeEvent();
      this.points = [];
      if (type.indexOf('free') > -1) {
        this.ablePan();
      }
      if(this.callback){
        this.callback({
          layer:this.layer,
          coordinates:this.coordinates,
        })
      }
    },
    //坐标数组尾部去重-防止双击时触发单击
    unRepetity() {
      if (this.points.length < 2) return;
      for (let i = this.points.length - 1; i > 0; i--) {
        var p = this.points[i];
        var p1 = this.points[i - 1];
        if (p[0] == p1[0] && p[1] == p1[1]) {
          this.points.splice(i, 1);
        } else {
          break;
        }
      }
    },
    //图形渲染
    render() {
      var geoType = this.getGeoType(type);
      if (this.layer == null) {
        this.layer = createLayer(geoType, this.coordinates);
      } else {
        let features = this.layer.getSource().getFeatures();
        let geometry = features[0].getGeometry();
        //geometry.coordinates = this.coordinates;

        switch (geoType) {
          case "Point":
            geometry.setCoordinates(this.coordinates[0]);
            break;
          case "LineString":
            geometry.setCoordinates(this.coordinates);
            break;
          case "Polygon":
            geometry.setCoordinates([this.coordinates]);
            break;
          default:
            break;
        }
      }
    },
    //获取几何类型
    getGeoType(type) {
      switch (type) {
        case "point":
          return "Point"
          break;
        case "line":
        case "freeline":
        case 'freelinestring':
        case 'beziercurve':
          return "LineString"
          break;
        default:
          return "Polygon"
          break;
      }
    },
    //绑定地图交互事件
    bindEvent() {
      var _this = this;
      this.eventClick = map.on('singleclick', function (e) {
        if (type.indexOf("free") > -1) return;
        _this.udpate(e.coordinate);
      })
      this.eventMove = map.on('pointermove', function (e) {
        if (type == "point") return;
        if (type.indexOf("free") > -1 && _this.isActive) {
          _this.udpate(e.coordinate);
        } else {
          _this.udpateTmp(e.coordinate);
        }
      })
      this.eventDbclick = map.on('dblclick', function (e) { 
        _this.udpate(e.coordinate);
        _this.stop();
        return false;
      })
      this.eventDown = map.on('pointerdown', function (e) {
        if (type.indexOf("free") > -1) {
          _this.isActive = true;
          _this.udpate(e.coordinate);
        }
      });
      this.eventUp = map.on('pointerup', function (e) {
        if (type.indexOf("free") > -1) {
          _this.isActive = false;
          _this.udpate(e.coordinate);
          _this.stop();
          return;
        }
      });

    },
    //解除地图交互事件
    removeEvent() {
      map.un('singleclick', this.eventClick.listener);
      map.un('dblclick', this.eventDbclick.listener);
      map.un('pointermove', this.eventMove.listener);
      map.un('pointerdown', this.eventDown.listener);
      map.un('pointerup', this.eventUp.listener);
    },
    //禁用拖动
    disablePan() {
      let pan = this.getPan();
      pan.setActive(false);
    },
    //启用拖动
    ablePan() {
      let pan = this.getPan();
      pan.setActive(true);
    },
    //获取拖动交互
    getPan() {
      let pan;
      map.getInteractions().forEach(function (element, index, array) {
        if (element instanceof DragPan) {
          pan = element;
        }
      })
      return pan;
    },
  }

  let design = {
    formatPnts(points) {
      var pointCollection = [];
      points.forEach(coordinate => {
        pointCollection.push({
          x: coordinate[0],
          y: coordinate[1]
        })
      })
      return pointCollection;
    },
    circle(points) {
      var center = points[0];
      var radius = Math.sqrt(Math.pow((points[0][0] - points[1][0]), 2) + Math.pow((points[0][1] - points[1][1]), 2))
      let coordinates = [];
      for (let i = 0; i < 360; i++) {
        var x = center[0] + radius * Math.sin(i * Math.PI / 180);
        var y = center[1] + radius * Math.cos(i * Math.PI / 180);
        coordinates.push([x, y]);
      }
      return coordinates;
    },
    beziercurve(points) {
      var pts = this.formatPnts(points);
      var coordinates = ltween.createBezierPath(pts);
      return coordinates;
    },
    bezierpolygon(points) {
      var pts = this.formatPnts(points);
      var coordinates = ltween.createBezierPathPoly(pts);
      return coordinates;
    },
    arrow(points) {
      var pts = this.formatPnts(points);
      var coordinates = ltween.createArrow(pts);
      return coordinates;
    },
    bezierarrow(points) {
      var pts = this.formatPnts(points);
      var coordinates = ltween.createFreeArrow(pts);
      return coordinates;
    },
    tailedsquadcombat(points) {
      //为兼容算法，变换点位格式
      var pnts = [];
      points.forEach(coordinate => {
        pnts.push({
          spatialReference: {
            wkid: 102100
          },
          type: "point",
          x: coordinate[0],
          y: coordinate[1]
        })
      })
      //计算
      var af = 0.1, ag = 1;
      var comfun = new comFun();
      var attackarrow = new attackArrow();
      attackarrow.constructor(points);
      //计算
      this.rings = [];
      var tailPnts = fz(pnts);
      var headPnts = attackarrow.fx(pnts, tailPnts[0], tailPnts[2]);
      var neckLeft = headPnts[0];
      var neckRight = headPnts[4];
      var bodyPnts = attackarrow.fy(pnts, neckLeft, neckRight, af);
      var count = bodyPnts.length;
      var leftPnts = [tailPnts[0]].concat(bodyPnts.slice(0, (count / 2)));
      leftPnts.push(neckLeft);
      var rightPnts = [tailPnts[2]].concat(bodyPnts.slice((count / 2), count));
      rightPnts.push(neckRight);

      leftPnts = comfun.fv(leftPnts);
      rightPnts = comfun.fv(rightPnts);
      var rings = leftPnts.concat(headPnts, rightPnts.reverse(), [tailPnts[1], leftPnts[0]]);

      var coordinates = [];
      rings.forEach(ring => {
        if (ring.x > 0) {
          coordinates.push([ring.x, ring.y]);
        }
      })
      return coordinates;

      function fz(points, tailLeft, tailRight) {
        var allLen = comfun.fd(points);
        var tailWidth = (allLen * af);
        var tailLeft = comfun.fl(points[1], points[0], comfun.HALF_PI, tailWidth, false);
        var tailRight = comfun.fl(points[1], points[0], comfun.HALF_PI, tailWidth, true);
        var len = (tailWidth * ag);
        var swallowTailPnt = comfun.fl(points[1], points[0], 0, len, true);
        return ([tailLeft, swallowTailPnt, tailRight]);
      }
    }
  }


  return drawer;
}

export default ldraw;