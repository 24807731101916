import Vue from "vue";

const config = {
  edit: "编辑",
  importArr: "导出",
  nianduArr: "按年度填报",
  listAll: "人员列表"
};

const operationPower = {
  state: () => ({
    edit: {},
    importArr: {},
    nianduArr: {},
    listAll: {}
  }),
  mutations: {
    INTI_OPERATION_POWER(state, { edit, importArr, nianduArr, listAll }) {
      state.edit = edit;
      state.importArr = importArr;
      state.nianduArr = nianduArr;
      state.listAll = listAll;
    }
  },
  actions: {
    async findPageOperateByUserName({ commit }) {
      let data = await Vue.prototype
        .$api("common.findPageOperateByUserName")
        .invoke({ operatetype: 19 });
      let yjData = data.data.data;

      let result = Object.keys(config).reduce((total, key) => {
        total[key] = yjData.reduce((_total, { operatename, pkOperateid }) => {
          if (operatename === config[key]) {
            _total[pkOperateid] = true;
          }
          return _total;
        }, {});
        return total;
      }, {});
      commit("INTI_OPERATION_POWER", result);
    }
  }
};

export default operationPower;
