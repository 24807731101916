/* eslint-disable indent */
/* eslint-disable no-undef */
import axios from "axios";
import { Message, Loading } from "element-ui";
import router from "../router";
// 提示
const tip = msg => {
  Message({
    message: msg,
    duration: 1000,
    type: "warning"
  });
};
// 跳转登录页
const toLogin = () => {
  router.replace({
    name: "loginIndex"
  });
};
// 请求错误后的统一处理
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    // 401: token过期,清除token并跳转登录页
    case 401:
      tip("登录过期，请重新登录");
      // localStorage.removeItem('token');
      /*删除所有的缓存*/
      // sessionStorage.clear();
      setTimeout(() => {
        // toLogin();
        // let url = "loginIndex";
        // if (sessionStorage.getItem("usertype") === "1") {
        //   console.log("企业");
        //   url = "loginIndex";
        // } else if (sessionStorage.getItem("usertype") === "0") {
        //   console.log("政府");
        //   url = "loginIndexZF";
        // } else if (sessionStorage.getItem("usertype") === "2") {
        //   console.log("第三方");
        //   url = "loginIndexSF";
        // }
        const signOutRouteName = sessionStorage.getItem("signOutRouteName");
        router.replace({
          path: signOutRouteName
        });
      }, 1000);
      break;
    // 404请求不存在
    case 404:
      tip("请求的资源不存在！");
      break;
    default:
      tip(other);
  }
};
// 创建新的axios实例，
const service = axios.create({
  headers: {
    "Content-type": "application/json;charset=UTF-8"
  }
  // timeout: 15000
});
let loading; // 定义loading变量
function startLoading() {
  // 使用Element loading-start 方法
  loading = Loading.service({
    // spinner: "el-icon-loading",
    lock: true,
    text: "",
    background: "rgba(0, 0, 0, 0.5)"
  });
}
function endLoading() {
  // 使用Element loading-close 方法
  loading && loading.close();
}

// showFullScreenLoading() tryHideFullScreenLoading() 将同一时刻的请求合并
// 声明一个变量 needLoadingRequestCount，每次调用showFullScreenLoading方法 needLoadingRequestCount + 1
let needLoadingRequestCount = 0;
// 定义一个延时函数，用来延迟执行loading的显示和隐藏
let debounceTimer = null;
function debounce(func, delay) {
  return function(...args) {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    debounceTimer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    // startLoading();
    debounce(startLoading, 300)();
  }
  needLoadingRequestCount += 1;
}
// 调用 tryHideFullScreenLoading()方法，needLoadingRequestCount-1。needLoadingRequestCount为 0时，结束 loading
function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount -= 1;
  if (needLoadingRequestCount === 0) {
    // endLoading();
    debounce(endLoading, 300)();
  }
}
// http请求拦截器
service.interceptors.request.use(
  config => {
    // 发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    config.headers.token = sessionStorage.getItem("token");
    // baseURL: process.env.VUE_APP_BASE_API
    // 如果data中没有hideLoading选项，或者hideLoading为false，就显示loading
    // 同时，如果params中没有hideLoading选项，或者hideLoading为false，也显示loading
    if (
      (!config.data || !config.data.hideLoading) &&
      (!config.params || !config.params.hideLoading)
    ) {
      showFullScreenLoading();
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
// 响应拦截器
service.interceptors.response.use(
  response => {
    if (response.data === undefined && response.request.responseText) {
      response.data = JSON.parse(response.request.responseText);
    }
    tryHideFullScreenLoading();
    return response.status === 200
      ? Promise.resolve(response)
      : Promise.reject(response);
  },
  error => {
    const { response } = error;
    errorHandle(response.status, response.data.message);
    tryHideFullScreenLoading();
    return Promise.reject(response);
  }
);

export default service;
