import { ADMIN, COMMON } from "@/config/interface.config.js";
export default {
  selectModelByUser: `post:/${ADMIN}/user/selectModelByUser`, //获取模块权限
  selectList: `post:/${ADMIN}/sysParam/getByList`, // 系统参数列表
  findPageOperateByUserName: `get:/${ADMIN}/user/findPageOperateByUserName`, // 获取按钮权限
  getCacheUser: `get:/${ADMIN}/login/getCacheUser`, // 获取用户信息
  getRegion: `get:/${COMMON}/pubCodeDiccode/getRegion`, // 根据权限获取行政区划
  getTreeRegion: `get:/${COMMON}/pubCodeDiccode/getTreeRegion`, //获取全国行政区划
  getTreeRegionByParent: `get:/${COMMON}/pubCodeDiccode/getTreeRegionByParent`, //获取行政区划的子行政区划（不包括本身
  domain: `get:/${COMMON}/TbBasDicDoman/findByDomainid`, // 字典
  findIndustrytype: `get:/${COMMON}/pubCodeDiccode/findIndustrytype`, //获取行业信息
  getParkInfo: `get:/${COMMON}/pubCodeDiccode/getParkInfo`, //获取园区信息
  getFileList: `post:/${COMMON}/datacenterfile/getAttachBykOtherIDList`, // 文件列表查询
  downloadBase64ImgSteam: `get:/${COMMON}/datacenterfile/downloadBase64ImgSteam`, //获取图片base64
  deleteFile: `get:/${COMMON}/datacenterfile/deleteFile`, // 根据文件id删除（单个删除）
  deleteByOtherID: `get:/${COMMON}/datacenterfile/deleteByOtherID`, // 根据数据关联id删除（批量删除）
  updatePwd: `post:/${ADMIN}/baseUserInfo/updatePwd`, // 更新密码
  getMapByName: `post:/${COMMON}/map/getMapByName`, // 天地图地图搜索
  getMapByNameGaoDe: `post:/${COMMON}/map/getMapByNameGaoDe`, // 高德地图搜索
  selectByCode: `get:/${COMMON}/pubCodeDiccode/selectByCode`, // 根据行政区划获取当前经纬度
  saveTongji: `post:/${ADMIN}/sysFunctionVisitRecord/save`, //菜单或者按钮点击访问量的统计
  getOrgAllByTree: `get:/${ADMIN}/baseOrganization/getOrgAllByTree` // 责任部门树
};
