import Vue from "vue";
const user = {
  state: () => ({
    useraccount: "",
    userid: "",
    username: "",
    usertype: ""
  }),
  getters: {
    isEnterpriseUser: state => state.usertype == "1"
  },
  mutations: {
    updateUserInfo(state, payload) {
      Object.assign(state, payload);
    }
  },
  actions: {
    async getCacheUser({ commit }) {
      let { data } = await Vue.prototype.$api("common.getCacheUser").invoke({});
      if (data.code == 1) {
        commit("updateUserInfo", data.data);
      } else {
        Vue.prototype.$message({
          showClose: true,
          message: data.data.msg,
          type: "error",
          duration: 2000
        });
      }
      return data.data;
    }
  }
};

export default user;
