// 统计分析
export default [
  {//视频访问量排名
    path: "fangwenliang",
    name: "fangwenliang",
    component: () => import("@/components/tongjifenxi/fangwenliang.vue")
  },
  {//视频使用情况列表
    path: "shiyongList",
    name: "shiyongList",
    component: () => import("@/components/tongjifenxi/shiyongList.vue")
  }
];
