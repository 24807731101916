import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import app from "./modules/app";
import menus from "./modules/menus.js";
import user from "./modules/user.js";
import operationPower from "./modules/operationPower.js";
import enterpriseReporting from "./enterpriseReporting/index";
import getters from "./getters";
Vue.use(Vuex);
export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    app,
    user,
    menus,
    operationPower,
    enterpriseReporting
  },
  getters,
  plugins: [createPersistedState({ storage: window.sessionStorage })]
});
