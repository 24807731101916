const enterpriseReporting = {
  state: {
    Reporting: {
      pollutionname: "", // 企业名称
      pkpollutionid: "", // 企业id
      enterpriseState: null, // 当前状态
      longitude: "",
      latitude: "",
      systemparameterList: {},
      systemparameterDetail: {},
      mySystemDataList: {}
    }
  },
  getters: {
    mapCenter: state => {
      let val = state.Reporting.systemparameterDetail.CenterJdWd;
      return val ? val.split(",").map(item => +item) : "";
    },
    getRegion: state => {
      let val = state.Reporting.systemparameterDetail.SystemRegionCode;
      return val ? val : "";
    }
  },
  mutations: {
    updatePollutionname: (state, pollutionname) => {
      state.Reporting = { ...state.Reporting, pollutionname };
    },
    updatePkpollutionid: (state, pkpollutionid) => {
      state.Reporting = { ...state.Reporting, pkpollutionid };
    },
    updateState: (state, enterpriseState) => {
      state.Reporting = { ...state.Reporting, enterpriseState };
    },
    updateLongitude: (state, longitude) => {
      state.Reporting = { ...state.Reporting, longitude };
    },
    updateLatitude: (state, latitude) => {
      state.Reporting = { ...state.Reporting, latitude };
    },
    systemparameterData: (state, systemparameterList) => {
      state.Reporting = {
        ...state.Reporting,
        systemparameterList,
        systemparameterDetail: systemparameterList.reduce(
          (res, { paramcode, paramvalue, fkIndustrytype, ...rest }) => {
            // 加上行业类型区分
            const key = [paramcode] + "_" + [fkIndustrytype];
            return {
              ...res,
              [key]: paramvalue,
              [paramcode]: paramvalue
            };
          },
          {}
        )
      };
    },
    mySystemData: (state, mySystemDataList) => {
      state.Reporting = { ...state.Reporting, mySystemDataList };
    }
  }
};
export default enterpriseReporting;
